import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import {
  GridDisplay,
  Loading,
  Snackbar,
  CustomDrawer,
  DropdownInput,
  ConfirmationPopup,
  TextField,
} from "components";
import { TICKET_CATEGORY_OPTIONS } from "constants";
import { UserContext } from "contexts";
import { getGridDisplayConfig } from "displayConfigs";
import { useSnackbar, useConfirmation } from "hooks";
import { TicketServices } from "services";
import { generateQueryParamsFromObject, generateDropdownOptions } from "utils";
import { AddTicketForm } from "../../index";
import TicketDetail from "./TicketDetail";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const MyTicketsList = ({ ticketStatus }) => {
  const snackbar = useSnackbar();
  const { currentUser } = useContext(UserContext);
  const [tickets, setTickets] = useState({
    list: [],
    loading: false,
  });
  const [fetchedTickets, setFetchedTickets] = useState([]);
  const [ticketFilters, setTicketFilters] = useState({
    userRole: currentUser?.role,
    searchTerm: ``,
    category: TICKET_CATEGORY_OPTIONS[0],
  });
  const [selectedTicketId, setSelectedTicketId] = useState(``);
  const [ticketDrawer, setTicketDrawer] = useState({
    isDrawerOpen: false,
    content: null,
    contentClass: ``,
  });

  useEffect(() => {
    fetchTicketsList();
  }, [ticketFilters]);

  useEffect(() => {
    setTickets({
      ...tickets,
      list: _.filter(
        fetchedTickets,
        ({ activeStatus }) => activeStatus === ticketStatus
      ),
    });
  }, [ticketStatus, fetchedTickets, ticketFilters]);

  const fetchTicketsList = async () => {
    try {
      setTickets({
        ...tickets,
        loading: true,
      });
      const query = generateQueryParamsFromObject({ ...ticketFilters });
      const { ticketsList } = await TicketServices.getTicketsList(query);
      setFetchedTickets(ticketsList);
      setTickets({
        ...tickets,
        loading: false,
        list: fetchedTickets?.filter(
          ({ activeStatus }) => activeStatus === ticketStatus
        ),
      });
    } catch (err) {
      snackbar.showMessage({
        content: err
      })
      setTickets({
        ...tickets,
        loading: false,
      });
    }
  };

  const onDeleteTicket = ({ _id }) => {
    setSelectedTicketId(_id);
    confirmationPopup.showPopup();
  };

  const myTicketsConfig = getGridDisplayConfig("Tickets", {
    onViewDetail: (record) => openTicketDrawer("VIEW", record),
    onDeleteTicket,
    onTabEmpty: () => ({
      message:
        ticketStatus === "Created"
          ? "It seems you have no active tickets"
          : "Your tickets are under review",
      subMessage:
        ticketStatus === "Created"
          ? "Raise a ticket if you have faced any issue."
          : "Resolved tickets will be displayed here.",
    }),
    onEmptyListButtonClick: () => openTicketDrawer("ADD"),
  });

  const openTicketDrawer = (contentType, record) =>
    setTicketDrawer({
      isDrawerOpen: true,
      content:
        contentType === "ADD" ? (
          <AddTicketForm
            onCloseDrawer={() => {
              setTicketDrawer({ isDrawerOpen: false });
            }}
          />
        ) : (
          <TicketDetail
            onCloseDrawer={() => setTicketDrawer({ isDrawerOpen: false })}
            onDeleteTicket={() => onDeleteTicket(record?._id)}
            ticketDetail={record}
          />
        ),
      contentClass:
        contentType === "ADD"
          ? `myTickets-ticketDrawer--add`
          : `myTickets-ticketDrawer--view`,
    });

  const handleResolveTicket = async () => {
    try {
      setTickets({
        ...tickets,
        loading: true,
      });
      const payload = {
        _id: selectedTicketId,
        ticketStatus: {
          description: "Resolved",
          timestamp: new Date(),
        },
      };
      const { msg, ticketData } = await TicketServices.createUpdateTicket(
        payload
      );
      setFetchedTickets((prevList) =>
        _.map(prevList, (ticket) =>
          ticket?._id === ticketData?._id
            ? {
                ...ticketData,
                activeStatus: "Resolved",
              }
            : ticket
        )
      );
      setTicketDrawer({ isDrawerOpen: false });
      snackbar.showMessage({
        content: msg,
      });
    } catch (err) {
      snackbar.showMessage({
        content: err,
      });
      setTickets({
        ...tickets,
        loading: false,
      });
    }
  };

  const confirmationPopup = useConfirmation({
    onConfirm: handleResolveTicket,
    onCancel: () => setSelectedTicketId(``),
  });

  const handleSearchTermChange = ({ target: { value } }) =>
    setTicketFilters({
      ...ticketFilters,
      searchTerm: value,
    });

  const handleCategorySelect = ({ target: { value } }) =>
    setTicketFilters({ ...ticketFilters, category: value });

  const confirmationMessage = (
    <div className="myTickets-popupContent">
      <p className="myTickets-popupContent-confirmQuestion">
        Are you sure you want to close your ticket?
      </p>
      <p className="myTickets-popupContent-confirmMessage">
        Your ticket is under review.
      </p>
    </div>
  );

  const { list, loading } = tickets;
  const { isDrawerOpen, content, contentClass } = ticketDrawer;
  const { searchTerm, category } = ticketFilters || {};

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <ConfirmationPopup {...confirmationPopup}>
        {confirmationMessage}
      </ConfirmationPopup>
      <div className="myTickets">
        <div className="myTickets__filters">
          <DropdownInput
            className="myTickets__filters-selectCategory"
            optionsList={generateDropdownOptions(TICKET_CATEGORY_OPTIONS)}
            selectedOption={category}
            onSelect={handleCategorySelect}
          />
          <TextField
            className="myTickets__filters-searchInput"
            type="text"
            value={searchTerm}
            placeholder="Search using title/description"
            onChange={handleSearchTermChange}
          />
        </div>
        <GridDisplay
          className="myTickets-overview"
          config={myTicketsConfig}
          dataList={list}
        />
        <CustomDrawer
          direction="bottom"
          className={contentClass}
          isOpen={isDrawerOpen}
          onCloseDrawer={() => setTicketDrawer({ isDrawerOpen: false })}
        >
          {content}
        </CustomDrawer>
      </div>
    </>
  );
};

export default MyTicketsList;
