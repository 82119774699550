import React, { useEffect, useState, useContext } from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Snackbar,
} from "@mui/material";
import _ from "lodash";
import { UserContext } from "contexts";
import { useSnackbar } from "hooks";
import { generateQueryParamsFromObject } from "utils";
import "./index.css";

const PaginatedTable = ({ config, queryFilters }) => {
  const snackbar = useSnackbar();
  const { currentUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNumber] = useState(0);
  const [pageData, setPageData] = useState({
    list: [],
    totalCount: 0,
  });
  const [sortData, setSortData] = useState({
    column: "_id",
    ascOrder: true,
  });
  const [pageLimit, setPageSize] = useState(10);

  const {
    columnHeaders,
    DisplayDataRow,
    fetchListData,
    dataKey,
    dataUnavailableMessage,
  } = config || {};

  const handlePageChange = (_ev, newPage) => setPageNumber(newPage);

  const onColumnSort = (columnVal) => {
    setSortData((prevSortData) => {
      return {
        column: columnVal,
        ascOrder:
          prevSortData?.column !== columnVal ? true : !prevSortData?.ascOrder,
      };
    });
  };

  const handlePageSizeChange = ({ target: { value } }) => {
    const newPageSize = parseInt(value);
    setPageSize((prevPageSize) => {
      setPageNumber((prevPageNumber) => {
        if (newPageSize > totalCount) return 0;
        else {
          const newPageNum = parseInt(
            (prevPageNumber * prevPageSize) / newPageSize
          );
          return _.max([newPageNum, 0]);
        }
      });
      return newPageSize;
    });
  };

  const fetchPageData = async () => {
    setLoading(true);
    try {
      const query = generateQueryParamsFromObject({
        ...queryFilters,
        sortBy: sortData?.column,
        order: sortData?.ascOrder ? "asc" : "desc",
        userRole: currentUser?.role,
        pageNum,
        pageLimit,
      });
      const data = await fetchListData(query);
      setPageData({
        list: data[`${dataKey}List`],
        totalCount: data[`${dataKey}Count`],
      });
    } catch (err) {
      snackbar.showMessage({
        content: err,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPageData();
  }, [pageLimit, pageNum, sortData, queryFilters]);

  const { list, totalCount } = pageData;

  const tableBody = !_.isEmpty(list) ? (
    list?.map((data, index) => <DisplayDataRow record={data} index={index} />)
  ) : (
    <TableRow>
      <TableCell className="noDataRow" colSpan={columnHeaders.length}>
        {dataUnavailableMessage || `No data to display`}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Snackbar {...snackbar} />
      <TableContainer className="div__tableList-container">
        <Table className="listTable">
          <TableHead>
            <TableRow className="headerRow">
              {_.map(columnHeaders, ({ label, key }) => (
                <TableCell
                  key={label}
                  className="headerColumn"
                  onClick={() => onColumnSort(key)}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow className="dataRow">
                <TableCell colSpan={columnHeaders?.length || 0}>
                  <CircularProgress className="loadingProgress" />
                </TableCell>
              </TableRow>
            ) : (
              tableBody
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={pageLimit}
        page={pageNum}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePageSizeChange}
      />
    </>
  );
};

export default PaginatedTable;
