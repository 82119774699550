import IconDisplay from "components/IconDisplay";
import { VendorServices } from "services";

const VENDOR_CONFIG = {
  screenName: "Vendors",
  header: (
    <p className="admin__vendorsList-header">
      <IconDisplay type="vendor" className="" /> All Vendors
    </p>
  ),
  columnHeaders: [
    { label: "Vendor Name", key: "name", type: "string" },
    { label: "Contact Number", key: "phoneNumber", type: "string" },
    { label: "Based At", key: "location", type: "string" },
    { label: "On Boarded At", key: "createdAt", type: "date" },
    { label: "Status", key: "status", type: "string" },
    { label: "Actions", key: "actions", type: "action" },
  ],
  fetchListData: VendorServices.fetchVendorsList,
  dataKey: "vendors",
  dataUnavailableMessage: (
    <div className="div__noData-message">
      <p className="messageTitle">No Vendors Found.</p>
    </div>
  ),
};

export default VENDOR_CONFIG;
