import PersonImage from "./assets/1.png";
import Person2Image from "./assets/2.png";
import Person3Image from "./assets/3.png";
import Person4Image from "./assets/4.png";
import Person5Image from "./assets/5.png";

export const TOP_LANGUAGES_LIST = [
  {
    _id: 1,
    imageURL: PersonImage,
    language: "Hindi",
    languageContent: "தமிழ்",
  },
  {
    _id: 2,
    imageURL: Person2Image,
    language: "Kashmiri",
    languageContent: "தமிழ்",
  },
  {
    _id: 3,
    imageURL: Person3Image,
    language: "Bengali",
    languageContent: "தமிழ்",
  },
  {
    _id: 4,
    imageURL: Person4Image,
    language: "Gondi",
    languageContent: "தமிழ்",
  },
  {
    _id: 5,
    imageURL: Person5Image,
    language: "Nepali",
    languageContent: "தமிழ்",
  },
  {
    _id: 6,
    imageURL: PersonImage,
    language: "Telugu",
    languageContent: "தமிழ்",
  },
  {
    _id: 7,
    imageURL: Person2Image,
    language: "Sindhi",
    languageContent: "தமிழ்",
  },
  {
    _id: 8,
    imageURL: Person3Image,
    language: "Tamil",
    languageContent: "தமிழ்",
  },
  {
    _id: 9,
    imageURL: Person4Image,
    language: "Dogri",
    languageContent: "தமிழ்",
  },
  {
    _id: 10,
    imageURL: Person5Image,
    language: "Konkani",
    languageContent: "தமிழ்",
  },
  {
    _id: 11,
    imageURL: PersonImage,
    language: "Urdu",
    languageContent: "தமிழ்",
  },
  {
    _id: 12,
    imageURL: Person2Image,
    language: "Kurukh",
    languageContent: "தமிழ்",
  },
  {
    _id: 13,
    imageURL: Person3Image,
    language: "Kannada",
    languageContent: "தமிழ்",
  },
  {
    _id: 14,
    imageURL: Person4Image,
    language: "Khandeshi",
    languageContent: "தமிழ்",
  },
  {
    _id: 15,
    imageURL: Person5Image,
    language: "Odia",
    languageContent: "தமிழ்",
  },
  {
    _id: 16,
    imageURL: PersonImage,
    language: "Tulu",
    languageContent: "தமிழ்",
  },
  {
    _id: 17,
    imageURL: Person2Image,
    language: "Malyalam",
    languageContent: "தமிழ்",
  },
  {
    _id: 18,
    imageURL: Person3Image,
    language: "Manipuri",
    languageContent: "தமிழ்",
  },
  {
    _id: 19,
    imageURL: Person4Image,
    language: "Punjabi",
    languageContent: "தமிழ்",
  },
  {
    _id: 20,
    imageURL: Person5Image,
    language: "Bodo",
    languageContent: "தமிழ்",
  },
  {
    _id: 21,
    imageURL: PersonImage,
    language: "Assamese",
    languageContent: "தமிழ்",
  },
  {
    _id: 22,
    imageURL: Person2Image,
    language: "Khasi",
    languageContent: "தமிழ்",
  },
  {
    _id: 23,
    imageURL: Person3Image,
    language: "Maithili",
    languageContent: "தமிழ்",
  },
  {
    _id: 24,
    imageURL: Person4Image,
    language: "Marwadi",
    languageContent: "தமிழ்",
  },
  {
    _id: 25,
    imageURL: Person5Image,
    language: "Gujarati",
    languageContent: "தமிழ்",
  },
  {
    _id: 26,
    imageURL: PersonImage,
    language: "Marathi",
    languageContent: "தமிழ்",
  },
];
