import React, { useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import _ from "lodash";
import {
  Image,
  Button,
  GridDisplay,
  IconDisplay,
  Loading,
  Collapse,
  ChatWindow,
} from "components";
import { UserContext } from "contexts";
import { getGridDisplayConfig } from "displayConfigs";
import { generateQueryParamsFromObject, encodeDecodeData } from "utils";
import { ProductServices } from "services";
import { TOP_LANGUAGES_LIST } from "tests/TopLanguagesData";
import { useResponsive } from "hooks";
import BannerImage from "assets/banner.png";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const LandingPage = () => {
  const { currentUser, modifyCurrentUserWishlist } = useContext(UserContext);
  const navigate = useNavigate();
  const [productsList, setProductsList] = useState([]);
  const { deviceType } = useResponsive();
  const topLanguagesLimit = deviceType === "mobile" ? 4 : 5;
  const [topLanguagesList, setTopLanguagesList] = useState(
    TOP_LANGUAGES_LIST.slice(0, topLanguagesLimit)
  );
  const restLanguagesList = _.difference(TOP_LANGUAGES_LIST, topLanguagesList);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const productGridConfig = getGridDisplayConfig("LatestAdditions", {
    onAddToWishlist: ({ _id, inWishlist }) => {
      setProductsList((prevList) => {
        const isSuccess = modifyCurrentUserWishlist(_id, inWishlist);
        return prevList.map((prod) => {
          if (prod._id === _id) {
            return {
              ...prod,
              inWishlist: isSuccess && !inWishlist,
            };
          }
          return prod;
        });
      });
    },
  });

  const topLanguagesGridConfig = getGridDisplayConfig("TopLanguages", {
    selectLanguageFilter: (contentLanguage) => {
      navigate(
        `/products/search?${createSearchParams({
          contentLanguage,
        })}`
      );
    },
  });

  useEffect(() => {
    getTopLanguagesList();
    fetchNewProducts();
  }, []);

  const getTopLanguagesList = async () => {
    setLoading(true);
    try {
      const { topLanguages } = await ProductServices.fetchTopLanguagesList(
        topLanguagesLimit
      );
      setTopLanguagesList(() => {
        const newTopLanguages = _.map(topLanguages, (languageText) =>
          _.find(TOP_LANGUAGES_LIST, ({ language }) =>
            _.isEqual(language, languageText)
          )
        );
        const remainingLanguages = _.filter(
          TOP_LANGUAGES_LIST,
          ({ language }) => !_.includes(topLanguages, language)
        );
        while (newTopLanguages.length < topLanguagesLimit) {
          newTopLanguages.push(remainingLanguages.shift());
        }
        return newTopLanguages;
      });
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const fetchNewProducts = async () => {
    setLoading(true);
    const wishlist = (currentUser?.wishlist || []).join(";");
    try {
      const query = generateQueryParamsFromObject({
        _w: !_.isEmpty(wishlist) && encodeDecodeData(wishlist, "encode"),
      });
      const res = await productGridConfig.fetchListData(query);
      setProductsList(res);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      <ChatWindow
        senderId={currentUser?._id}
        receiverName="MemeT Admin"
        buttonClass="div__landingPage-Chat__FAB"
      />
      {loading && <Loading />}
      <div className="div__landingPage-container">
        <Image imageURL={BannerImage} className="imgBanner" />
        <div className="div__landingPage-topLanguages">
          OUR TOP REGIONAL LANGUAGE COLLECTIONS
        </div>
        <GridDisplay
          className="div__landingPage-topLanguages-list"
          itemClassName="div__language-card"
          config={topLanguagesGridConfig}
          dataList={topLanguagesList}
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <GridDisplay
            className="div__landingPage-topLanguages-list"
            itemClassName="div__language-card"
            config={topLanguagesGridConfig}
            dataList={restLanguagesList}
          />
        </Collapse>
        <Button
          id="btnInput"
          size="large"
          className="btnViewCollections"
          onClick={() => setExpanded(!expanded)}
        >
          <IconDisplay type="checkroom" />
          {expanded ? `Show Less Collections` : `View All Collections`}
        </Button>
        <div className="div__landingPage-latestAdditions">
          OUR LATEST ADDITIONS
        </div>
        <GridDisplay
          className="div__landingPage-latestAdditions-list"
          config={productGridConfig}
          dataList={productsList}
        />
      </div>
    </>
  );
};

export default LandingPage;
