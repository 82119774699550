import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import _ from "lodash";
import { VideoPreview, Loading } from "components";
import { UserContext } from "contexts";
import { generateQueryParamsFromObject, encodeDecodeData } from "utils";
import { ProductServices } from "services";
import DetailContent from "./DetailContent";
import ProductRatings from "./ProductRating";
import "./index.css";
import "./tablet.css";
import "./mobile.css";

const ProductDetailPage = ({ isPreview, previewProduct, previewInventory }) => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(UserContext);
  const [productDetail, setProductDetail] = useState({
    productData: {},
    inventoryData: [],
    sizeOptions: [],
    availableSizeVariations: {},
  });
  const params = useParams();
  const locationState = useLocation().state;

  useEffect(() => {
    if (!isPreview) {
      fetchProductDetails();
    } else {
      const { inventoryList, sizeOptions, availableSizeVariations } =
        previewProduct || {};

      setProductDetail({
        productData: previewProduct || {},
        inventoryData: previewInventory || inventoryList || [],
        sizeOptions,
        availableSizeVariations,
      });
    }
  }, [params.id, isPreview, currentUser?.cart]);

  const fetchProductDetails = async () => {
    try {
      setLoading(true);
      const wishlist = (currentUser?.wishlist || []).join(";");
      const cartItems = currentUser?.cart
        ?.map(({ inventoryId, quantity }) => `${inventoryId?._id}#${quantity}`)
        .join(";");
      const query = generateQueryParamsFromObject({
        _w: !_.isEmpty(wishlist) && encodeDecodeData(wishlist, "encode"),
        _c: !_.isEmpty(cartItems) && encodeDecodeData(cartItems, "encode"), // Sending encoded cart request to check inCart inventory items
      });
      const {
        productData,
        inventoryData,
        availableSizeVariations,
        sizeOptions,
      } = await ProductServices.getProductById(params.id, query);
      setProductDetail({
        productData,
        inventoryData,
        sizeOptions,
        availableSizeVariations,
      });
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const { _id, video, rating, ratingsCount } = productDetail.productData;

  return (
    <>
      {loading && <Loading />}
      <div className="div__productDetail-topContainer">
        {_id && (
          <DetailContent
            isPreview={isPreview}
            productDetail={productDetail}
            inventoryIdSelected={locationState?.inventoryId} // Selected inventory Id from cart page
            setLoading={setLoading}
            loading={loading}
          />
        )}
      </div>
      {video && (
        <div className="div__productDetail-middleContainer">
          <div className="div__productDetail-memePreview">
            <p>ENJOY OUR MEME MOVIE!</p>
            <VideoPreview
              videoSource={video}
              className="videoPreview"
              frameClassname="memeVideoPreview"
              // width={800}
              // height={450}
            />
          </div>
        </div>
      )}
      {!isPreview && ratingsCount > 0 && (
        <div className="div__productDetail-bottomContainer">
          <ProductRatings
            productId={_id}
            rating={rating}
            ratingsCount={ratingsCount}
          />
        </div>
      )}
    </>
  );
};

export default ProductDetailPage;
