import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import io from "socket.io-client";
import { Loading } from "components";
import { UserContext } from "contexts";
import ChatDetails from "./ChatDetails";
import UserList from "./UserList";
import ChatWindow from "./ChatWindow";
import "./index.css";

const socketIO = io(process.env.REACT_APP_BACKEND_URL, {
  autoConnect: false,
});

const AdminChat = () => {
  const [chatList, setChatList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(UserContext);
  const [selectedChat, setSelectedChat] = useState({});

  const registerSocketEvents = () => {
    socketIO.on("connect", connectCallBack);
    socketIO.on("disconnect", disconnectCallback);
    socketIO.on("fetch_error", errorCallBack);
    socketIO.on("get_chats_list_response", fetchChatListCallback);
    socketIO.on("continue_chat_response", fetchChatList);
    socketIO.on("close_chat_response", fetchChatList);
    socketIO.on("create_chat_response", fetchChatList);
  };

  const errorCallBack = (err) => console.log("Error occured", err);

  const connectCallBack = () => {
    setLoading(true);
    fetchChatList();
  };

  const fetchChatList = () =>
    socketIO.emit("get_chats_list_request", { userId: currentUser?._id });

  const fetchChatListCallback = (chatListResp) => {
    setLoading(false);
    setChatList(chatListResp);
    setSelectedChat(_.first(chatListResp));
  };

  const disconnectCallback = () => console.log(`Socket Disconnected`);

  const handleSelectChat = (chatData) => setSelectedChat(chatData);

  const onCloseChat = () => {
    setLoading(true);
    socketIO.emit("close_chat_confirmation_request", {
      chatId: selectedChat?._id,
    });
  };

  useEffect(() => {
    socketIO.connect();
    registerSocketEvents();

    return () => {
      socketIO.disconnect().close();
    };
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="AdminChat__container">
        <div className="div__UserList__container">
          <UserList
            chatList={chatList}
            senderId={currentUser?._id}
            onSelectChat={handleSelectChat}
          />
        </div>

        <ChatWindow
          selectedChat={selectedChat}
          currentUser={currentUser}
          socketIO={socketIO}
        />

        {!_.isEmpty(selectedChat) && (
          <div className="div__chatDetails-Container">
            <ChatDetails
              selectedChat={selectedChat}
              onCloseChat={onCloseChat}
              orderData={selectedChat?.orderData}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AdminChat;
