import { Axios } from "utils";

const TicketServices = {};
const BASE_URL = process.env.REACT_APP_TICKETS_API;

TicketServices.getTicketsList = async (query) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}?${query}`);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

TicketServices.createUpdateTicket = async (payload) => {
  try {
    const { data } = await Axios.post(BASE_URL, payload, {
      header: { "content-type": "multipart/form-data" },
    });
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

export default TicketServices;
