import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TextField, /*Badge,*/ Avatar, Collapse } from "@mui/material";
import { Autocomplete } from "@mui/lab";
import { ActionIcon, /*IconDisplay,*/ Image, Footer } from "components";
import { AUTHENTICATION_ROUTES } from "constants";
import { UserContext } from "contexts";
import { ProductServices } from "services";
import MenuDrawer from "./MenuDrawer";
import { displayDriveImage } from "utils";
import { useResponsive } from "hooks";
import MemeTLogo from "assets/Meme-T-Logo.png";
import "./index.css";

const MainLayout = ({ children }) => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { _id, name, /*cart,*/ profilePic } = currentUser || {};
  const [searchTerm, setSearchTerm] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { deviceType } = useResponsive();
  const signedInUser = _id && _id !== "GuestId";
  const showNavbar = !_.some(
    AUTHENTICATION_ROUTES,
    (route) => location?.pathname === route
  );
  const isDeviceMobile = deviceType === "mobile";

  useEffect(() => {
    document.getElementById("routesContainer").style.paddingTop = `${
      !showNavbar ? "0" : "70"
    }px`;
  }, [showNavbar]);

  const hideMobileSearch = () => setShowMobileSearch(false);

  const searchInputClassname = isDeviceMobile
    ? "menu__container-search--mobile"
    : "menu__container-search";

  const fetchSearchSuggestions = async (query) => {
    setLoading(true);
    try {
      const { searchResults } = await ProductServices.searchProductSuggestions(
        query
      );
      setSearchSuggestions(searchResults);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const onSelectSuggestion = (_ev, val) => {
    setSearchTerm(val);
    const result = _.find(searchSuggestions, ({ title }) => val === title);
    navigate(`/product/${result?._id}`);
  };

  const delayedFetchSearchSuggestions = _.debounce(
    fetchSearchSuggestions,
    2000
  );

  const searchProductsInput = (
    <>
      <div className="menu__container-searchBar">
        <Autocomplete
          freeSolo
          filterOptions={(result) => result}
          onChange={onSelectSuggestion}
          options={_.map(searchSuggestions, ({ title }) => title)}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="txtSearchTerm"
              placeholder="Search your product..."
              onChange={({ target: { value } }) => {
                delayedFetchSearchSuggestions.cancel();
                setSearchTerm(value);
                return delayedFetchSearchSuggestions(value);
              }}
            />
          )}
        />
      </div>
      {isDeviceMobile && (
        <ActionIcon
          type="arrowUp"
          iconClassName="menu__container-searchBar-hide"
          onClick={hideMobileSearch}
        />
      )}
    </>
  );

  const searchTextInput = !isDeviceMobile
    ? searchProductsInput
    : !showMobileSearch && (
        <ActionIcon
          type="search"
          className="mobileSearchInput"
          iconClassName="iconMobileSearch"
          onClick={() => setShowMobileSearch(!showMobileSearch)}
        />
      );

  return (
    <div className="mainLayout">
      {showNavbar && (
        <>
          <nav
            className={`menu ${
              isDeviceMobile && showMobileSearch ? "menu__expanded" : ""
            }`}
            // className="menu"
          >
            <div className="menu__top">
              <div className="menu__logo">
                {/*Displaying menu icon only if user signed in */}
                {/*TODO: Commenting navbar menu for vendor demo */}
                {/* {signedInUser && (
                  <ActionIcon
                    type="menu"
                    id="iconMenu"
                    onClick={() => setMenuOpen(!menuOpen)}
                  />
                )} */}
                <Link to="/">
                  <Image className="img__Logo" imageURL={MemeTLogo} />
                </Link>
              </div>
              <div className={searchInputClassname}>{searchTextInput}</div>
              <div className="menu__links">
                {!signedInUser && <Link to="/login">Sign In</Link>}
                {/*Removed signed in user conjugation for route guarding*/}
                {/*TODO: Commented Orders route for vendor demo */}
                {/* {signedInUser && (
                  <Link to="/orders" className="menu__links-myOrders">
                    My Orders
                  </Link>
                )} */}
                {signedInUser && (
                  <Link to={`/profile/${_id}`}>
                    <div className="menu__profileLink">
                      <Avatar src={displayDriveImage(profilePic)} />
                      <p className="menu__profileLink-userName">{name}</p>
                    </div>
                  </Link>
                )}
                {/* TODO: Commented for Vendor Demo */}
                {/* <Link to="/cart">
                  <Badge badgeContent={cart?.length} color="primary">
                    <IconDisplay type="shoppingCart" id="iconCart" />
                  </Badge>
                </Link> */}
              </div>
            </div>
            {isDeviceMobile && (
              <Collapse
                in={showMobileSearch}
                timeout={500}
                className="menu__bottom-collapse"
              >
                <div className="menu__bottom">{searchProductsInput}</div>
              </Collapse>
            )}
          </nav>
          <MenuDrawer
            isMenuOpen={menuOpen}
            onToggleMenu={() => setMenuOpen(!menuOpen)}
          />
        </>
      )}
      {children}
      {showNavbar && <Footer />}
    </div>
  );
};

export default MainLayout;
