import React, { useState, useRef } from "react";
import _ from "lodash";
import {
  DragAndDropUpload,
  IconDisplay,
  Button,
  Loading,
  Snackbar,
} from "components";
import { FILE_TYPES } from "constants";
import { Axios } from "utils";
import { useSnackbar } from "hooks";
import "./index.css";

const BulkUploadPage = () => {
  const [uploadedFile, setUploadedFile] = useState();
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const inputRef = useRef();

  const handleBulkUpload = async () => {
    if (!_.isEmpty(uploadedFile)) {
      const formData = new FormData();
      formData.append("template", uploadedFile);
      setLoading(true);
      try {
        const {
          data: { msg },
        } = await Axios.post(`/api/product/bulkUpload`, formData, {
          header: { "content-type": "multipart/form-data" },
        });
        snackbar.showMessage({
          content: msg,
        });
        setUploadedFile(undefined);
        inputRef.current.clearSelectedFiles();
      } catch (err) {
        snackbar.showMessage({
          content: err,
        });
      }
      setLoading(false);
    }
  };

  const discardSelectedFile = () => {
    inputRef.current.clearSelectedFiles();
    setUploadedFile(undefined);
  };

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <div className="bulkUpload__container">
        <p className="bulkUpload__header">
          <IconDisplay type="upload" className="bulkUpload__header-icon" /> Bulk
          Upload
        </p>
        <p className="bulkUpload__header--text">
          Add Zip File for your products
        </p>
        <DragAndDropUpload
          ref={inputRef}
          label={`Drag and Drop .zip file for bulk upload products`}
          maxFiles={1}
          className="bulkUpload__fileInput"
          acceptedTypes={[FILE_TYPES.FILE_ZIP]}
          onDropHandler={([acceptedFile]) => setUploadedFile(acceptedFile)}
        />

        {!_.isEmpty(uploadedFile) && (
          <div className="file-upload__buttons">
            <Button
              id="btnInput"
              className="file-upload__buttons-accept"
              onClick={handleBulkUpload}
            >
              Upload
            </Button>
            <Button
              className="file-upload__buttons-cancel"
              onClick={discardSelectedFile}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default BulkUploadPage;
