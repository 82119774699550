import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ErrorMessage } from "components";
import "./index.css";

const TextInputField = ({
  name,
  className,
  iconComponent,
  inputType,
  obscureText,
  labelText,
  placeholder,
  forgotURL,
  inputValue,
  handleChange,
  handleEnterPress,
  handleBlur,
  errorText,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const fieldType = showPassword ? "text" : "password";

  return (
    <div className="div__txtInput">
      <div className="div__txtInput_label">
        {iconComponent ? (
          <div className="div__txtInput_label-icon">
            {iconComponent}
            <p className="lblInput">{labelText}</p>
          </div>
        ) : (
          <p className="lblInput">{labelText}</p>
        )}
        {forgotURL && (
          <Link
            to={forgotURL}
            className="lnkForgot"
          >{`Forgot ${labelText}?`}</Link>
        )}
      </div>
      <TextField
        name={name}
        className={`txtInput ${className}`}
        type={obscureText ? fieldType : inputType}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyPress={(ev) => ev.key === "Enter" && handleEnterPress(inputValue)}
        onBlur={handleBlur}
        disabled={disabled}
        InputProps={{
          endAdornment: inputValue && obscureText && (
            <InputAdornment position="end">
              <IconButton
                disableRipple
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ErrorMessage errorText={errorText} />
    </div>
  );
};

export default TextInputField;
