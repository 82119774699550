import React from "react";
import { Routes, Route } from "react-router-dom";
import { USER_ROLES } from "constants";
import { RouteAuth, generateRoute } from "./helpers";
import COMMON_ROUTES from "./commonRoutes";
import CUSTOMER_ROUTES from "./customerRoutes";
import VENDOR_ROUTES from "./vendorRoutes";
import SUPER_ADMIN_ROUTES from "./superAdminRoutes";

const { VENDOR, SUPER_ADMIN } = USER_ROLES;

const AllRoutes = () => (
  <Routes>
    {COMMON_ROUTES.map(generateRoute)}
    <Route element={<RouteAuth />}>
      {CUSTOMER_ROUTES.map(generateRoute)}
    </Route>
    <Route element={<RouteAuth allowedRoles={[VENDOR, SUPER_ADMIN]} />}>
      {VENDOR_ROUTES.map(generateRoute)}
    </Route>
    <Route element={<RouteAuth allowedRoles={[SUPER_ADMIN]} />}>
      {SUPER_ADMIN_ROUTES.map(generateRoute)}
    </Route>
  </Routes>
);

export default AllRoutes;
