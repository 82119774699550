import React, { forwardRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
} from "@mui/material";
import { Image, ActionIcon } from "components";
import MemeTLogo from "assets/Meme-T-Logo.png";
import "./index.css";

const TransitionSlide = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmationPopup = ({
  isPopupOpen,
  children,
  handleConfirm,
  handleClose,
  actionButtons,
}) => {
  const handleYesButtonClick = () => {
    handleClose();
    handleConfirm();
  };

  const displayButtons = actionButtons || (
    <DialogActions>
      <Button className="button_no" onClick={handleClose}>
        No
      </Button>
      <Button className="button_yes" onClick={handleYesButtonClick}>
        Yes
      </Button>
    </DialogActions>
  );

  return (
    <Dialog
      open={isPopupOpen}
      TransitionComponent={TransitionSlide}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <div className="div__popup-header">
          <ActionIcon
            type="clear"
            className="closeButton"
            iconClassName="iconClose"
            onClick={handleClose}
          />
          <Image imageURL={MemeTLogo} className="imgMemeTLogo" />
        </div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className="div__popup-buttons">
        {displayButtons}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
