import { Axios } from "utils";

const ProductServices = {};
const BASE_URL = process.env.REACT_APP_PRODUCT_API;

ProductServices.getProductsList = async (query) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/search?${query}`);
    const { success, productsList } = data || {};
    if (success) {
      return productsList;
    }
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

ProductServices.getProductsListForVendor = async (query) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/vendor?${query}`);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

ProductServices.getNewlyAddedProducts = async (query) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/new?${query}`);
    const { success, productsList } = data || {};
    if (success) {
      return productsList;
    }
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

ProductServices.getRecommendedProducts = async (query) => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/recommended?${query}`);
    const { success, productsList } = data || {};
    if (success) {
      return productsList;
    }
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

ProductServices.getProductById = async (productId, query = "") => {
  try {
    const { data } = await Axios.get(`${BASE_URL}/${productId}?${query}`);
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

ProductServices.createUpdateProduct = async (payload) => {
  try {
    const { data } = await Axios.post(`${BASE_URL}/`, payload);
    const { success, msg, productData } = data;
    if (success) {
      return { msg, productData };
    }
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

ProductServices.deleteProduct = async (productId) => {
  try {
    const { data } = await Axios.delete(`${BASE_URL}/${productId}`);
    if (data?.success) {
      return data?.msg;
    }
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

ProductServices.fetchTopLanguagesList = async (topLanguagesLimit) => {
  try {
    const { data } = await Axios.get(
      `${BASE_URL}/topLanguages?topLanguagesLimit=${topLanguagesLimit}`
    );
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

ProductServices.searchProductSuggestions = async (searchTerm) => {
  try {
    const { data } = await Axios.get(
      `${BASE_URL}/searchSuggestions?searchTerm=${searchTerm}`
    );
    return data;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};

export default ProductServices;
