import React, {
  memo,
  forwardRef,
  useState,
  useMemo,
  useCallback,
  useImperativeHandle,
} from "react";
import { useDropzone } from "react-dropzone";
import { FileIcon, defaultStyles } from "react-file-icon";
import PropTypes from "prop-types";
import _ from "lodash";
import { IconDisplay, Button, Image } from "components";
import { displayFileUrl } from "utils";
import { acceptStyle, focusedStyle, rejectStyle, iconStyle } from "./styles";
import "./index.css";

const DragAndDropUpload = forwardRef(
  ({ label, maxFiles, className, acceptedTypes, onDropHandler }, ref) => {
    const [acceptedFiles, setAcceptedFiles] = useState();
    const [rejectedFiles, setRejectedFiles] = useState();

    useImperativeHandle(ref, () => ({
      clearSelectedFiles() {
        setAcceptedFiles(undefined);
        setRejectedFiles(undefined);
      },
    }));

    const onDrop = useCallback(
      (acceptedFilesInput, rejectedFilesInput) => {
        !_.isEmpty(rejectedFilesInput) && setRejectedFiles(rejectedFilesInput);
        !_.isEmpty(acceptedFilesInput) && setAcceptedFiles(acceptedFilesInput);

        onDropHandler(acceptedFilesInput, rejectedFilesInput);
      },
      [acceptedFiles, rejectedFiles]
    );

    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      onDrop,
      maxFiles,
      accept: _.join(acceptedTypes, ","),
    });

    const style = useMemo(
      () => ({
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <div
        className={`file-upload__container ${className}`}
        {...getRootProps({ style })}
      >
        <input {...getInputProps()} />
        {_.isEmpty(acceptedFiles) && _.isEmpty(rejectedFiles) && (
          <>
            <IconDisplay type="bulkUpload" className="file-upload__icon" />
            <p className="file-upload__text">{label}</p>
            <p className="file-upload__text">OR</p>
            <Button id="btnInput" className="" onClick={() => getInputProps()}>
              Browse File
            </Button>
          </>
        )}
        <div className="file-upload__accepted-files">
          {_.map(acceptedFiles, (fileInput) => {
            const { name, size, type } = fileInput;
            const fileExtension = name.split(".").pop();
            const logoStyle = { ...defaultStyles[fileExtension], ...iconStyle };
            return _.includes(type, "image/") ? (
              <Image
                className="acceptedImage"
                imageURL={displayFileUrl(fileInput)}
              />
            ) : (
              <div style={iconStyle}>
                <FileIcon extension={fileExtension} {...logoStyle} />
                {name} {size}
              </div>
            );
          })}
        </div>

        <div className="file-upload__rejected-files">
          {_.map(rejectedFiles, ({ file: { name }, errors }) => (
            <span>
              {name} -{" "}
              {_.map(errors, (e, index) =>
                index === errors.length - 1 ? e.message : `${e.message}, `
              )}
            </span>
          ))}
        </div>
      </div>
    );
  }
);

DragAndDropUpload.propTypes = {
  label: PropTypes.string.isRequired,
  maxFiles: PropTypes.number,
  className: PropTypes.string,
  acceptedTypes: PropTypes.arrayOf(PropTypes.string),
};

DragAndDropUpload.defaultProps = {
  label:
    "Error, you forgot to put in what needs to be uploaded here a.k.a. LABEL",
  maxFiles: 4,
  className: ``,
  acceptedTypes: [],
};

export default memo(DragAndDropUpload);
