import React from "react";
import moment from "moment";
import { TextInputField } from "components";
import _ from "lodash";
import Axios from "axios";

export const generateInputFields = (
  { values, handleChange, handleBlur, touched, errors },
  inputsArray
) =>
  inputsArray.map(({ name, icon, inputType, labelText, disabled }) => (
    <TextInputField
      key={name}
      name={name}
      iconComponent={icon}
      inputType={inputType}
      labelText={labelText}
      obscureText={inputType === `password`}
      inputValue={values[name]}
      handleChange={handleChange}
      handleBlur={handleBlur}
      disabled={disabled}
      errorText={touched[name] && errors[name]}
    />
  ));

export const modifyList = (prevList, inputVal) =>
  prevList.includes(inputVal)
    ? prevList.filter((val) => val !== inputVal)
    : [...prevList, inputVal];

export const displayCurrencyAmount = (amount, maximumFractionDigits = 0) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits,
    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(Math.abs(amount));
};

export const generateQueryParamsFromObject = (queryObj) =>
  Object.keys(queryObj)
    .filter((key) => {
      const queryVal = queryObj[key];
      if (_.isArray(queryVal) || _.isObject(queryVal))
        return !_.isUndefined(queryVal) && !_.isEmpty(queryVal);
      else if (_.isNumber(queryVal)) return true;
      return queryVal;
    })
    .map(
      (queryKey) =>
        `${queryKey}=${
          _.isDate(queryObj[queryKey])
            ? queryObj[queryKey].toISOString()
            : queryObj[queryKey] || ""
        }`
    )
    .join("&");

export const applyDebounce = (targetFunction, wait, immediate = false) => {
  let timeout;
  return function (...args) {
    const later = () => {
      timeout = null; // added this to set same behaviour as ES5
      if (!immediate) targetFunction.apply(this, args); // this is called conditionally, just like in the ES5 version
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) targetFunction.apply(this, args);
  };
};

export const displayFileUrl = (file) =>
  typeof file === "object" ? URL.createObjectURL(file) : file;

export const generateDropdownOptions = (optionsList) =>
  optionsList?.map((option) => ({ label: option, value: option }));

export const displayDateInFormat = (dateVal, dateFormat) =>
  moment(dateVal).format(dateFormat);

export const isDateExpired = (dateInput) =>
  Date.parse(dateInput) <= Date.now();

export const displayDriveImage = (imgId) =>
  _.includes(imgId, "googleusercontent") || _.includes(imgId, "fbsbx")
    ? imgId
    : `https://drive.google.com/uc?export=view&id=${imgId}`;

export const encodeDecodeData = (data, type) =>
  type === "encode" ? btoa(data) : atob(data);

export const deleteSelectedDriveImages = async (selectedFiles) => {
  try {
    const { data } = await Axios.post(`/images/delete`, { selectedFiles });
    return data?.success;
  } catch (err) {
    throw err?.response?.data?.msg;
  }
};
