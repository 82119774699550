import React, { forwardRef } from "react";
import { Dialog, DialogTitle, DialogContent, Slide } from "@mui/material";
import { ActionIcon } from "components";
import "./index.css";

const FormDialog = ({ isPopupOpen, slideDirection, children, onClose }) => {
  const TransitionSlide = forwardRef((props, ref) => (
    <Slide direction={slideDirection || "up"} ref={ref} {...props} />
  ));

  return (
    <Dialog
      open={isPopupOpen}
      TransitionComponent={TransitionSlide}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <ActionIcon
          type="clear"
          className="closeButton"
          iconClassName="iconClose"
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default FormDialog;
