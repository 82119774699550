export const SIDEBAR_MENU_ITEMS = [
  { text: "My Profile", icon: "person", link: "/profile" },
  { text: "My Orders", icon: "shoppingCart", link: "/myOrders" },
  { text: "My Tickets", icon: "ticket", link: "/myTickets" },
  {
    text: "My Wishlist",
    icon: "favoriteBorder",
    link: "/wishlist",
    hasDivider: true,
  },
  {
    text: "Latest Additions",
    icon: "checkroom",
    link: `/products/search?latestAdditions=true`,
  },
  {
    text: "Language Categories",
    custom: true,
    hasDivider: true,
  },
  { text: "Sign Out", icon: "logout", link: "/login" },
];

export const VENDOR_SIDEBAR_MENU_ITEMS = [
  { text: "My Dashboard", icon: "dashboard", link: "/vendor/dashboard" },
  {
    text: "My Wallet",
    icon: "wallet",
    link: `/vendor/wallet`,
    hasDivider: true,
  },
  { text: "All Products", icon: "ticket", link: "/products" },
  {
    text: "Add Product",
    icon: "addCircle",
    link: "/product/add",
    hasDivider: true,
  },
  { text: "All Orders", icon: "shoppingCart", link: "/orders" },
  { text: "All Tickets", icon: "ticket", link: "/products", hasDivider: true },
  { text: "Sign Out", icon: "logout", link: "/login" },
];

export const AUTHENTICATION_ROUTES = [
  "/login",
  "/register",
  "/forgotPassword",
  "/unauthorized",
  "/vendor/onboarding",
  "/order/success",
  "*",
];
