import React from "react";
import _ from "lodash";
import "./index.css";

const MessageCard = ({ isReceived, message, timestamp }) => {
  const messageType = isReceived ? `ReceiveCard` : `SendCard`;
  const messageElement = _.isString(message) ? (
    <p
      className={`${messageType}__text`}
      dangerouslySetInnerHTML={{ __html: message }}
    ></p>
  ) : (
    <p className={`${messageType}__text`}>{message}</p>
  );
  return (
    <>
      <div className={`${messageType}__Container`}>{messageElement}</div>
      <p className={`${messageType}__time`}>{timestamp}</p>
    </>
  );
};

export default MessageCard;
