import { EmptyListPlaceholder } from "components";
import EmptyOrdersList from "assets/EmptyOrdersList.png";
import OrderCard from "pages/Orders/MyOrders/MyOrdersList/OrderCard";

const ORDERS_GRID_CONFIG = {
  containerSpacing: 2,
  renderComponent: (
    value,
    {
      onCancelOrder,
      onRaiseTicket,
      onViewDetail,
      onSubmitOrderFeedback,
      onViewTicketDetail,
      onDownloadInvoice,
    }
  ) => (
    <OrderCard
      order={value}
      onCancelOrder={() => onCancelOrder(value)}
      onRaiseTicket={() => onRaiseTicket(value)}
      onViewDetail={() => onViewDetail(value)}
      onSubmitOrderFeedback={() => onSubmitOrderFeedback(value)}
      onViewTicketDetail={() => onViewTicketDetail(value)}
      onDownloadInvoice={() => onDownloadInvoice(value)}
    />
  ),
  emptyDataContent: ({ onEmptyListButtonClick }) => (
    <EmptyListPlaceholder
      placeholderImage={EmptyOrdersList}
      imageClass="myOrders-imgEmptyList"
      message="No orders present in the list."
      onButtonClick={onEmptyListButtonClick}
      buttonLabel="Shop for Items"
    />
  ),
};

export default ORDERS_GRID_CONFIG;
