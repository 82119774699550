import "./index.css";
import { ActionIcon } from "components";

const ChatHeader = ({ userName, isOnline, isTyping, onHideChatWindow }) => (
  <div className="AdminchatHeader__Container">
    <div className="AdminChatHeader__Container">
      <p className="AdminChat__header">{userName}</p>
      <p className="AdminChat__subheader">{!isTyping ? isOnline ? "Online" : "" : "typing..."}</p>
    </div>

    <ActionIcon
      type="clear"
      className="icon__closeChat"
      onClick={onHideChatWindow}
    />
  </div>
);

export default ChatHeader;
