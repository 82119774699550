import React, { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  PaginatedTable,
  TextInputField,
  Button,
  CustomDrawer,
  ActionIcon,
  Loading,
  Snackbar,
} from "components";
import { getTableDisplayConfig } from "displayConfigs";
import { ProductServices } from "services";
import { useSnackbar } from "hooks";
import { ProductDetailPage } from "pages";
import ProductInventory from "./ProductInventory";
import "./index.css";
import "./mobile.css";

const ProductListPage = () => {
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState("");
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const [selectedProduct, setSelectedProduct] = useState();

  const productConfig = getTableDisplayConfig(
    "Product",
    {
      actions: (rowVal) => {
        return (
          <div className="div_actionIcons">
            <ActionIcon
              type="view"
              iconClassName="listIcon"
              onClick={() => setSelectedProduct(rowVal)}
            />
            <ActionIcon
              type="edit"
              iconClassName="listIcon"
              onClick={() => navigate(`/product/update/${rowVal?._id}`)}
            />
            <ActionIcon
              type="delete"
              iconClassName="listIcon"
              onClick={async () => {
                try {
                  setLoading(true);
                  const msg = await ProductServices.deleteProduct(rowVal._id);
                  snackbar.showMessage({
                    content: msg,
                    handleClose: fetchProductsList,
                  });
                } catch (err) {
                  setLoading(false);
                  console.log(err);
                }
              }}
            />
          </div>
        );
      },
    },
    ({ inventoryList, ...productDetails }) => (
      <ProductInventory
        inventoryItems={inventoryList}
        selectedProduct={productDetails}
      />
    )
  );

  const fetchProductsList = async () => {
    try {
      setLoading(true);
      const { productsList } = await productConfig.fetchListData(``);
      setProductList(productsList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackbar.showMessage({
        content: err,
      });
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <div className="div__productList">
        <p className="headerAddProduct">Products</p>
        <div className="div__productList-container">
          <div className="div__productList-filters">
            {/* Filters */}
            <TextInputField
              className="searchKey"
              labelText="Search"
              placeholder="Search by Product Title..."
              inputValue={searchKey}
              handleChange={({ target: { value } }) => setSearchKey(value)}
            />
            <Button
              id="btnInput"
              size="large"
              className="btnAddInventory"
              onClick={() => navigate(`/product/add`)}
            >
              Add New Item
            </Button>
          </div>
          <div className="div__productList-tableContainer">
            <PaginatedTable
              config={productConfig}
              queryFilters={{ searchKey }}
              dataList={productList}
            />
            <CustomDrawer
              direction="bottom"
              className="productDetailDrawer"
              isOpen={!_.isUndefined(selectedProduct)}
              onCloseDrawer={() => setSelectedProduct(undefined)}
            >
              <ProductDetailPage
                isPreview={true}
                previewProduct={selectedProduct}
                onCloseDrawer={() => setSelectedProduct(undefined)}
              />
            </CustomDrawer>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductListPage;
