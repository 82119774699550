import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Loading, Snackbar, Button, Image } from "components";
import VendorTermsImage from "assets/Vendor/VendorTerms.svg";
import VendorOnboardingSuccess from "assets/Vendor/VendorOnboardingSuccess.svg";
import { VendorContext, UserContext } from "contexts";
import { VendorServices } from "services";
import { useSnackbar } from "hooks";
import "./index.css";
import "./mobile.css";
const VendorAgreement = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [vendorCreated, setVendorCreated] = useState(false);
  const { currentVendor } = useContext(VendorContext);
  const { fetchUserData } = useContext(UserContext);

  const createNewVendor = async () => {
    try {
      const { onboardingStep, ...vendorData } = currentVendor;
      const formData = new FormData();
      _.each(vendorData, (value, key) => {
        if(key === 'address') {
          for (const addressKey in vendorData['address']) {
            formData.append(`address[${addressKey}]`, vendorData['address'][addressKey]);
          }
        }
        else {
          formData.append(key, value);
        }
      });
      setLoading(true);
      const { token, tokenExp } = await VendorServices.createUpdateVendor(
        `create`,
        formData
      );
      localStorage.setItem("_t", token);
      localStorage.setItem("_tokenExp", tokenExp);
      await fetchUserData();
      setLoading(false);
      setVendorCreated(true);
      setTimeout(() => navigate(`/vendor/dashboard`), 5000);
    } catch (err) {
      setLoading(false);
      snackbar.showMessage({
        content: err,
      });
    }
  };

  const agreementList = [
    `Lorem ipsum dolor sit amet, consect etur adipiscing elit, sed do eiusmod tempor
    incididunt ut labore et dolore magna aliqua. `,
    `Lorem ipsum dolor sit amet, consect etur adipiscing elit, sed do eiusmod tempor
    incididunt ut labore.`,
    `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremq`,
    `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremq`,
  ];

  const declineTerms = () => console.log(`Vendor Declined Terms`);

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <div className="vendorAgreement__form">
        {!vendorCreated && (
          <>
            <div className="vendorAgreement__form--top">
              <p className="vendorAgreement__form--top--left">
                User Agreement to Policy and Terms and Conditions
              </p>
              <Image
                imageURL={VendorTermsImage}
                className="vendorAgreement__form--top--right"
              />
            </div>
            <div className="vendorAgreement__form-termsAndConditions">
              {agreementList?.map((statement, index) => (
                <p className="vendorAgreement__form-termsAndConditions-statement">
                  {`${index + 1}. ${statement}`}
                </p>
              ))}
            </div>
            <div className="vendorAgreement__form-buttons">
              <Button
                id="btnInput"
                className="vendorAgreement__form-agreeTerms"
                disableRipple
                onClick={createNewVendor}
              >
                Agree
              </Button>
              <Button
                className="vendorAgreement__form-declineTerms"
                disableRipple
                onClick={declineTerms}
              >
                Decline
              </Button>
            </div>
          </>
        )}
        {vendorCreated && (
          <div className="vendorAgreement__form-success">
            <Image
              imageURL={VendorOnboardingSuccess}
              className="vendorAgreement__form-success-image"
            />
            <p className="vendorAgreement__form-success-message">
              Your registration has been completed successfully. You will be
              redirected to your dashboard.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default VendorAgreement;
