import React from "react";
import CheckoutButton from "../CheckoutButton";
import SelectAddress from "./SelectAddress";
import OrderBilling from "./OrderBilling";
import "./index.css";

const OrderCheckout = ({ currentUser }) => {
  const { cart, address } = currentUser || {};

  return (
    <div className="div__userCart-orderCheckout">
      <SelectAddress
        address={address}
      />
      <OrderBilling cart={cart} />
      <CheckoutButton userDetails={currentUser} />
    </div>
  );
};

export default OrderCheckout;
