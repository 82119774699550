import React, { useState, createContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const SearchContext = createContext();

const SearchContextProvider = ({ children }) => {
  const [searchFilter, setSearchFilter] = useState(undefined);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const filterObj = {};
    ["contentLanguage", "searchTerm"].forEach((key) => {
      const val = searchParams?.get(key);
      if (val) {
        filterObj[key] = val;
      } else {
        delete filterObj[key];
      }
    });

    if (searchParams?.get("latestAdditions")) {
      setSearchFilter({ sortBy: "createdAt", order: "desc", limit: 5 });
      return;
    }

    setSearchFilter({ ...filterObj });
  }, [searchParams]);

  const modifySearchFilters = (key, value) => {
    setSearchFilter((prevFilters) => {
      prevFilters[key] = value;
      if (!prevFilters[key]) {
        delete prevFilters[key];
      }
      return { ...prevFilters };
    });
  };

  const modifySortFilters = (sortBy, order, limit = 50) => {
    setSearchFilter((prevFilters) => ({
      ...prevFilters,
      sortBy,
      order,
      limit,
    }));
  };

  return (
    <SearchContext.Provider
      value={{
        searchFilter,
        setSearchFilter,
        modifySearchFilters,
        modifySortFilters,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
