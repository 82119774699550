import React from "react";
import _ from "lodash";
import { Button } from "components";
import { displayDateInFormat } from "utils";
import "./index.css";

const ChatDetails = ({ orderData, onCloseChat, selectedChat }) => {
  const { isClosed, createdAt, updatedAt } = selectedChat || {};
  const { orderName, vendorName, receipt } = orderData || {};
  return (
    <div className="div__ChatDetails-container">
      <div className="div__ChatDetails">
        <div className="ChatDetails__header">Chat Details</div>

        <div className="ChatDetails_Date">
          <p className="ChatDetails__Date-header">Started On :</p>
          <p className="ChatDetails__Date">
            {displayDateInFormat(createdAt, "DD/MM/YYYY HH:mm")}
          </p>
        </div>

        {!_.isEmpty(orderData) && (
          <>
            <div className="ChatDetails__SelectedOrder">
              <p className="ChatDetails__SelectedOrder-header">
                {" "}
                Selected Order :
              </p>
              <p className="ChatDetails__SelectOrder__OrderItem">{orderName}</p>
            </div>

            <div className="ChatDetails__SelectedOrderID">
              <p className="ChatDetails__SelectedOrderID-header">Order ID :</p>
              <p className="ChatDetails__SelectOrderID__ID">{receipt}</p>
            </div>

            <div className="ChatDetails__VendorDetails">
              <p className="ChatDetails__VendorDetails-header"> Vendor Name</p>
              <p className="ChatDetails__VendorDetails__Name">{vendorName}</p>
              {/* <p className="ChatDetails__VendorDetails__Contact">{VendorContact}</p> */}
            </div>
          </>
        )}
      </div>
      {isClosed && (
        <div className="div__Chat-Container-isClosed">
          This chat was closed on{" "}
          {displayDateInFormat(updatedAt, "DD/MM/YYYY HH:mm")}
        </div>
      )}
      {!isClosed && (
        <div className="div__ChatDetails-actions">
          <Button
            className="div__ChatDetails-actions-closeChat"
            onClick={onCloseChat}
          >
            Close Chat
          </Button>
        </div>
      )}
    </div>
  );
};

export default ChatDetails;
