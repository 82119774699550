import React, { useState } from "react";
import { TabDisplay, Button, CustomDrawer } from "components";
import { getTabDisplayConfig } from "displayConfigs";
import "./index.css";
import "./mobile.css";
import "./tablet.css";
import { AddTicketForm } from "../index";

const MyTicketsPage = () => {
  const tabConfig = getTabDisplayConfig("Tickets", []);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onCloseDrawer = async () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className="myTickets__container">
      <div className="myTickets__header">
        {tabConfig.screenName}
        <Button
          id="btnInput"
          className="ticket_detail-addTicket"
          onClick={() => setIsDrawerOpen(true)}
        >
          RAISE TICKET
        </Button>
      </div>
      <TabDisplay config={tabConfig} />
      <CustomDrawer
        direction="bottom"
        className="myTickets__ticketDrawer--add"
        isOpen={isDrawerOpen}
        onCloseDrawer={() => setIsDrawerOpen(false)}
      >
        <AddTicketForm onCloseDrawer={onCloseDrawer} />
      </CustomDrawer>
    </div>
  );
};

export default MyTicketsPage;
