import React, { useState } from "react";
import _ from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import { Star } from "@mui/icons-material";
import { TextField } from "@mui/material";
import {
  Snackbar,
  Loading,
  Button,
  CustomRating,
  DetailCard,
  AddImagesWithPreview,
} from "components";
import { useSnackbar } from "hooks";
import { FEEDBACK_RATINGS } from "constants";
import { FeedbackServices } from "services";
import { modifyList } from "utils";
import OrderItems from "../../OrderDetail/OrderItems";
import "./index.css";

const ProductFeedbackForm = ({
  customerId,
  orderItems,
  feedbackDetail,
  isPreview,
  onSubmitForm,
}) => {
  const snackbar = useSnackbar();
  const { _id, rating, images, comment } = feedbackDetail || {};
  const [selectedFeedbackItems, setSelectedFeedbackItems] = useState([]);
  const [feedbackImages, setFeedbackImages] = useState(images || []);

  return (
    <Formik
      initialValues={{
        comment: comment || ``,
        rating: rating || 0,
      }}
      validationSchema={Yup.object().shape({
        comment: Yup.string().max(
          200,
          "Feedback Description should not exceed 200 characters"
        ),
        rating: Yup.number().test(
          "rating",
          "Rating is mandatory",
          (val) => val !== 0
        ),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values, { setSubmitting }) => {
        const payload = {
          ...values,
          customerId,
          productIds: _.uniq(
            _.map(selectedFeedbackItems, ({ productItem }) => productItem?._id)
          ),
          feedbackType: "Product",
        };
        if (_id) {
          payload["_id"] = _id;
        }
        setSubmitting(true);
        const { msg } = await FeedbackServices.createUpdateFeedback(payload);
        setSubmitting(false);
        snackbar.showMessage({
          content: msg,
          timeout: 5000,
          handleClose: onSubmitForm,
        });
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldValue,
        isSubmitting,
        handleSubmit,
      }) => (
        <>
          <Snackbar {...snackbar} />
          {isSubmitting && <Loading />}
          <div className="productFeedback__form">
            <p className="productFeedback__form-header">PRODUCT FEEDBACK</p>
            <div className="productFeedback__form--leftRight">
              <div className="productFeedback__form--left">
                {orderItems && (
                  <OrderItems
                    products={orderItems}
                    isSelectMode
                    headerText={`Items Selected: ${selectedFeedbackItems?.length}`}
                    selectedItems={selectedFeedbackItems}
                    onSelectItem={(orderItem) =>
                      setSelectedFeedbackItems((prevList) =>
                        modifyList(prevList, orderItem)
                      )
                    }
                  />
                )}
              </div>
              <div className="productFeedback__form--right">
                <div className="productFeedback__form-rating">
                  <p className="productFeedback__form-rating-header">
                    Rate our Product
                  </p>
                  <CustomRating
                    rating={values.rating}
                    customIcon={({ value, ...props }) => (
                      <div className="productFeedback__form-rating-container">
                        <Star
                          className="productFeedback__form-rating-icon"
                          {...props}
                        />
                        <p className="productFeedback__form-rating-label">
                          {FEEDBACK_RATINGS[value]}
                        </p>
                      </div>
                    )}
                    size="large"
                    onRatingChange={(val) =>
                      !isPreview && setFieldValue("rating", val)
                    }
                  />
                  {touched.rating && errors.rating && (
                    <p className="productFeedback__form-rating-error">
                      {errors.rating}
                    </p>
                  )}
                </div>

                <DetailCard
                  className="productFeedback__form-images"
                  headerContent="Add Your Images"
                  headerClass="productFeedback__form-images-header"
                  cardContent={
                    <AddImagesWithPreview
                      previewDisabled
                      htmlFor="addInventoryImage"
                      imagesList={feedbackImages}
                      onImageUpload={(imgList) => setFeedbackImages(imgList)}
                      handleRemoveImage={(imageURL) =>
                        setFeedbackImages((prevList) =>
                          prevList.filter((imgURL) => imgURL !== imageURL)
                        )
                      }
                    />
                  }
                  contentClass="productFeedback__form-images-content"
                />

                <div className="productFeedback__form-description">
                  <TextField
                    name="comment"
                    className="productFeedback__form-descriptionInput"
                    variant="standard"
                    placeholder="Tell us what you liked about the product"
                    value={values.comment}
                    onChange={!isPreview && handleChange}
                    onBlur={handleBlur}
                  />
                  <p className="productFeedback__form-description-label">
                    Your feedback helps Meme - T improve service.
                  </p>
                </div>
                <div className="productFeedback__form-buttons">
                  {!isPreview && (
                    <Button
                      id="btnInput"
                      className="productFeedback__form-submit"
                      onClick={handleSubmit}
                    >
                      Submit Feedback
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <div className="productFeedback__form-images">
              {/* Drag and Drop */}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default ProductFeedbackForm;
