import React, { useEffect, useState, useContext } from "react";
import ReactDOMServer from "react-dom/server";
import io from "socket.io-client";
import _ from "lodash";
import { ChatHeader, ChatFooter, ChatList, FAB } from "components";
import ChatQuestionCard from "../ChatQuestionCard";
import { UserContext } from "contexts";
import "./index.css";
import { OrderPrompt } from "../index";

const socketIO = io(process.env.REACT_APP_BACKEND_URL, {
  autoConnect: false,
});

const HELP_CATEGORIES = [
  "Order Related",
  "Delivery Related",
  "Refunds Related",
  "Others",
];

const ChatWindow = ({ receiverName, buttonClass }) => {
  const { currentUser } = useContext(UserContext);
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [helpCategoriesList, setHelpCategoriesList] = useState(HELP_CATEGORIES);
  const [messagesList, setMessagesList] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(``);
  const [senderId, setSenderId] = useState(
    currentUser?.name ? currentUser?._id : ``
  );
  const [pageNum, setPageNum] = useState(0);
  const [helpCategorySelected, setHelpCategorySelected] = useState(``);
  const [orderSelected, setOrderSelected] = useState(``);
  const [newUserDetails, setNewUserDetails] = useState({
    name: ``,
    email: ``,
  });
  const [isAskingQueryDetails, setIsAskingQueryDetails] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const registerSocketEvents = () => {
    socketIO.on("connect", connectCallBack);
    socketIO.on("help_categories_response", populateHelpCategories);
    socketIO.on("get_chat_response", getChatCallBack);
    socketIO.on("send_message_response", sendNewMessageCallback);
    socketIO.on("create_chat_response", createNewChatCallback);
    socketIO.on("create_new_user_chat_response", createNewUserChatCallback);
    socketIO.on("continue_chat_response", continueExistingChatCallback);
    socketIO.on("fetch_orders_list_response", fetchOrdersListCallback);
    socketIO.on(
      "close_chat_confirmation_response",
      askConfirmationForCloseChat
    );
    socketIO.on("disconnect", disconnectCallback);
    socketIO.on("fetch_error", errorCallBack);
    socketIO.on("fetch_typing", fetchTypingCallback);
  };

  const selectOrderForChat = (orderItem, ordersList) => {
    if (
      orderItem === "Others" &&
      ordersList.length > 0 &&
      ordersList.length === 3
    ) {
      setPageNum((prevNum) => {
        const orderType = _.first(_.split(helpCategorySelected, " "));
        fetchOrdersForSelection(orderType, prevNum + 1);
        return prevNum + 1;
      });
      return;
    }
    setOrderSelected(() => {
      setMessagesList((prevList) => [
        ...prevList,
        {
          isReceived: false,
          messageCategory: `content`,
          messageContent: <OrderPrompt {...orderItem?.props} />,
          timestamp: new Date(),
        },
        {
          isReceived: true,
          messageCategory: `content`,
          messageContent: `Please provide details about your query`,
          timestamp: new Date(),
        },
      ]);
      setIsAskingQueryDetails(true);
      return orderItem?.props;
    });
  };

  const fetchOrdersListCallback = (ordersList) => {
    const ordersDisplayList = _.map(ordersList, (orderItem) => (
      <OrderPrompt {...orderItem} />
    ));
    if (ordersList.length > 0 && ordersList.length === 3) {
      ordersDisplayList.push("Others");
    }
    const displayOrdersForSelectionMessage = {
      isReceived: true,
      messageCategory: `content`,
      messageContent: (
        <ChatQuestionCard
          header="Select the order you want assistance for."
          optionsList={ordersDisplayList}
          onSelectOption={(orderItem) =>
            selectOrderForChat(orderItem, ordersList)
          }
        />
      ),
      timestamp: new Date(),
    };
    setMessagesList((prevList) => [
      ...prevList,
      displayOrdersForSelectionMessage,
    ]);
  };

  const populateHelpCategories = (helpCategoriesResponse) => {
    const helpCategoriesData = _.map(
      helpCategoriesResponse,
      ({ title }) => title
    );
    setHelpCategoriesList((_data) => helpCategoriesData);
    if (_.isEmpty(currentChatId)) renderHelpIntroduction(helpCategoriesData);
  };

  const populateMessagesList = (messages) => {
    setMessagesList(
      _.map(messages || [], (msg) => ({
        ...msg,
        messageCategory: `content`,
        isReceived: msg.sender !== senderId,
      }))
    );
  };

  const createNewUserChatCallback = ({ chatData, userId }) => {
    setCurrentChatId(() => chatData?._id);
    setSenderId(() => userId);
    setMessagesList(
      _.map(chatData?.messages || [], (msg) => ({
        ...msg,
        messageCategory: `content`,
        isReceived: msg.sender !== userId,
      }))
    );
  };

  const createNewChatCallback = (newChatData) => {
    setCurrentChatId((_prevId) => newChatData?._id);
    populateMessagesList(newChatData?.messages);
  };

  const continueExistingChatCallback = (existingChatData) => {
    setCurrentChatId(existingChatData?._id);
    populateMessagesList(existingChatData?.messages);
  };

  const createUpdateNewChat = (messageList) => {
    let eventName,
      payload = {};
    const messagesPayload = _.map(
      messageList,
      ({ isReceived, messageContent, timestamp }) => ({
        sender: isReceived
          ? process.env.REACT_APP_SUPER_ADMIN_USER_ID
          : senderId,
        messageContent: ReactDOMServer.renderToString(messageContent),
        timestamp,
      })
    );
    if (_.isEmpty(currentChatId)) {
      if (!_.isEmpty(senderId)) {
        eventName = `create_chat_request`;
        payload = {
          isClosed: false,
          userIds: [senderId, process.env.REACT_APP_SUPER_ADMIN_USER_ID],
          messages: messagesPayload,
        };
        if (!_.isEmpty(orderSelected?._id)) {
          const { orderName, receipt, vendorName } = orderSelected;
          payload["orderData"] = {
            orderName,
            receipt,
            vendorName,
          };
        }
      } else {
        eventName = `create_new_user_chat_request`;
        const { name, email } = newUserDetails;
        payload = {
          name,
          email,
          superAdminId: process.env.REACT_APP_SUPER_ADMIN_USER_ID,
          messages: messagesPayload,
          isClosed: false,
        };
      }
    } else {
      eventName = `continue_chat_request`;
      payload = {
        chatId: currentChatId,
        messages: messagesPayload,
      };
    }

    socketIO.emit(eventName, payload);
  };

  const askUserQuery = (categoryTitle) => {
    setHelpCategorySelected(() => {
      if (categoryTitle !== "Others") {
        setMessagesList((prevList) => [
          ...prevList,
          {
            isReceived: false,
            messageCategory: `content`,
            messageContent: categoryTitle,
            timestamp: new Date(),
          },
        ]);
        const orderType = _.first(_.split(categoryTitle, " "));
        fetchOrdersForSelection(orderType, pageNum);
      } else {
        const askQueryMessages = [
          {
            isReceived: false,
            messageCategory: `content`,
            messageContent: `I want to raise a query regarding ${categoryTitle}`,
            timestamp: new Date(),
          },
          {
            isReceived: true,
            messageCategory: `content`,
            messageContent: `Please provide details about your query`,
            timestamp: new Date(),
          },
        ];
        setMessagesList((prevList) => [...prevList, ...askQueryMessages]);
        setIsAskingQueryDetails(true);
      }
      return categoryTitle;
    });
  };

  const fetchOrdersForSelection = (orderType, pageNumVal) => {
    socketIO.emit("fetch_orders_list_request", {
      customerId: currentUser?._id,
      orderType,
      pageNum: pageNumVal,
      pageLimit: 3,
    });
  };

  const checkConfirmationOption = (optionChosen, chatId) => {
    if (optionChosen === "YES") {
      socketIO.emit("close_chat_request", chatId);
      const closeChatMessage = {
        isReceived: true,
        messageCategory: `content`,
        messageContent: `Thank you for chatting with us. Have a nice Day`,
        timestamp: new Date(),
      };
      setMessagesList((prevList) => [...prevList, closeChatMessage]);
      setTimeout(() => {
        setCurrentChatId(() => ``);
        setShowChatWindow(false);
      }, 5000);
    } else {
      reRenderHelpIntroduction();
    }
  };

  const reRenderHelpIntroduction = () => {
    const reInitateChatMessage = {
      isReceived: true,
      messageCategory: `content`,
      messageContent: (
        <ChatQuestionCard
          header="Which additional category you require help with?"
          optionsList={helpCategoriesList}
          selectedOption={helpCategorySelected}
          onSelectOption={askUserQuery}
        />
      ),
      timestamp: new Date(),
    };
    setMessagesList((prevList) => [...prevList, reInitateChatMessage]);
  };

  const askConfirmationForCloseChat = ({ chatId }) => {
    const confirmationMessage = {
      isReceived: true,
      messageCategory: `content`,
      messageContent: (
        <ChatQuestionCard
          header="Would you like to close this chat?"
          optionsList={["YES", "NO"]}
          onSelectOption={(option) => checkConfirmationOption(option, chatId)}
        />
      ),
      timestamp: new Date(),
    };
    setMessagesList((prevList) => [...prevList, confirmationMessage]);
  };

  const renderHelpIntroduction = (helpCategories) => {
    const introMessage = {
      isReceived: true,
      messageCategory: `content`,
      messageContent: (
        <ChatQuestionCard
          header="Hello! How may I help you?"
          optionsList={helpCategories}
          onSelectOption={askUserQuery}
        />
      ),
      timestamp: new Date(),
    };
    setMessagesList((prevList) => {
      return [...prevList, introMessage];
    });
  };

  const errorCallBack = (err) => console.log("Error occured", err);

  const fetchTypingCallback = (userId) => {
    setIsTyping(userId !== senderId);
    setTimeout(() => setIsTyping(false), 5000);
  };

  const sendNewMessageCallback = (updatedMessages) =>
    populateMessagesList(updatedMessages);

  const connectCallBack = () => {
    console.log(`Socket IO Connected`, socketIO);
    if (!_.isEmpty(currentUser?.name)) {
      getActiveChatForLoggedInCustomer();
    } else {
      askNewUserForName();
    }
  };

  const askNewUserForName = () => {
    const newUsernameMessage = {
      isReceived: true,
      messageCategory: `content`,
      messageContent: `Welcome to Meme-T Chat Support. Please enter your name.`,
      timestamp: new Date(),
    };
    setMessagesList((prevList) => {
      return [...prevList, newUsernameMessage];
    });
  };

  const getActiveChatForLoggedInCustomer = () => {
    socketIO.emit("get_chat_request", { userId: currentUser?._id });
  };

  const getChatCallBack = (chatData) => {
    if (!_.isEmpty(chatData)) {
      const { _id, messages } = chatData;
      setCurrentChatId(_id);
      populateMessagesList(messages);
    } else socketIO.emit("help_categories_request");
  };

  const disconnectCallback = () => console.log(`Socket Disconnected`);

  useEffect(() => {
    registerSocketEvents();
    socketIO.connect();

    return () => {
      setMessagesList([]);
      socketIO.disconnect().close();
    };
  }, []);

  const populateNewUserDetailsWithInput = (key, input) => {
    setNewUserDetails((prevDetails) => {
      if (key === "name") {
        setMessagesList((prevList) => [
          ...prevList,
          {
            isReceived: false,
            messageContent: input,
            messageCategory: `content`,
            timestamp: new Date(),
          },
          {
            isReceived: true,
            messageContent: `Greetings ${input}. Please enter your email.`,
            messageCategory: `content`,
            timestamp: new Date(),
          },
        ]);
      } else if (key === "email") {
        const isValidEmail = validateEmailInput(input);
        setMessagesList((prevList) => {
          const newMessagesList = [
            ...prevList,
            {
              isReceived: false,
              messageContent: input,
              messageCategory: `content`,
              timestamp: new Date(),
            },
          ];
          if (!isValidEmail) {
            newMessagesList.push({
              isReceived: true,
              messageContent: `Entered email is invalid. Please enter your email in correct format.`,
              messageCategory: `content`,
              timestamp: new Date(),
            });
          }
          return newMessagesList;
        });
        if (!isValidEmail) return prevDetails;
      }
      return {
        ...prevDetails,
        [key]: input,
      };
    });
  };

  const validateEmailInput = (emailInput) => {
    const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
    return emailRegex.test(emailInput);
  };

  const handleSendMessage = (messageContent) => {
    const newMessage = {
      messageContent,
      messageCategory: "content",
      timestamp: new Date(),
    };

    if (_.isEmpty(senderId)) {
      if (!newUserDetails.name) {
        populateNewUserDetailsWithInput("name", messageContent);
        return;
      } else if (!newUserDetails.email) {
        populateNewUserDetailsWithInput("email", messageContent);
        if (!validateEmailInput(messageContent)) return;
        renderHelpIntroduction(helpCategoriesList);
        return;
      }
    }

    if (!_.isEmpty(senderId) && !isAskingQueryDetails) {
      socketIO.emit("send_message_request", {
        chatId: currentChatId,
        message: {
          sender: currentUser?._id,
          ...newMessage,
        },
      });
    }

    setMessagesList((prevList) => {
      const newMessagesList = [
        ...prevList,
        {
          isReceived: false,
          ...newMessage,
        },
      ];

      if (isAskingQueryDetails) {
        newMessagesList.push({
          isReceived: true,
          messageContent: `Sorry to hear about that! Our support team will get back to resolve your query in about 2 hours`,
          messageCategory: `content`,
          timestamp: new Date(),
        });
        setIsAskingQueryDetails(false);
        createUpdateNewChat(newMessagesList);
      }
      return newMessagesList;
    });
  };

  const setTypingStatus = () => socketIO.emit("send_typing", currentUser?._id);

  return (
    <>
      {showChatWindow && (
        <div className="chat__Container">
          <ChatHeader
            userName={receiverName || "Meme-T Admin"}
            isTyping={isTyping}
            onHideChatWindow={() => setShowChatWindow(false)}
          />
          <ChatList messages={messagesList} chatId={currentChatId} />
          <ChatFooter
            onTyping={setTypingStatus}
            onSendMessage={handleSendMessage}
          />
        </div>
      )}
      {!showChatWindow && (
        <FAB
          icon={!showChatWindow ? "chat" : "clear"}
          className={buttonClass}
          onClick={() => setShowChatWindow(!showChatWindow)}
        />
      )}
    </>
  );
};

export default ChatWindow;
