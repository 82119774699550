import React from "react";
import { Fab } from "@mui/material";
import { IconDisplay } from "components";

const FAB = ({ icon, iconClassName, ...restProps }) => (
  <Fab {...restProps}>
    <IconDisplay type={icon} iconClassName={iconClassName} />
  </Fab>
);

export default FAB;
