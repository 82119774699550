import React, { useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import classnames from "classnames";
import { MainLayout } from "./components";
import { UserContext } from "contexts";
import { useResponsive } from "hooks";
import AllRoutes from "routes";
import "./App.css";

const App = () => {
  const { deviceType } = useResponsive();
  const { currentUser } = useContext(UserContext);
  // const homePageComponent =
  //   _.isEmpty(currentUser?._id) || _.isEqual(currentUser?._id, "GuestId")
  //     ? AboutUs
  //     : VendorMainDashboard;
  return !currentUser?._id ? null : (
    <BrowserRouter>
      <div className={classnames(`appContainer`, deviceType)}>
        <MainLayout>
          <div id="routesContainer">
            <AllRoutes />
          </div>
        </MainLayout>
      </div>
    </BrowserRouter>
  );
};

export default App;
