import React, { useEffect, useContext } from "react";
import { useLocation, Navigate, Outlet, Route } from "react-router-dom";
import _ from "lodash";
import { USER_ROLES } from "constants";
import { UserContext } from "contexts";

export const COMMON_ROUTES = {
  HOME: "/",
  PAGE_NOT_FOUND: "*",
  UNAUTHORIZED: "/unauthorized",
  ABOUT_US: "/aboutUs",
  FORGOT_PASSWORD: "/forgotPassword",
  LOGIN: "/login",
  REGISTER: "/register",
  VIEW_PROFILE: "/profile/:id",
  EDIT_PROFILE: "/profile/edit",
  SEARCH_PRODUCTS: "/products/search",
  PRODUCT_DETAIL: "/product/:id",
  ORDER_SUCCESS: "/order/success",
};

export const CUSTOMER_ROUTES = {
  CART: "/cart",
  CHECKOUT: "/checkout",
  MY_ORDERS: "/myOrders",
  MY_TICKETS: "/myTickets",
  MY_MEME_SUGGESTIONS: "/myMemeSuggestions",
  MY_WISHLIST: "/wishlist",
};

export const VENDOR_ROUTES = {
  VENDOR_LOGIN: "/vendor/login",
  VENDOR_ONBOARDING: "/vendor/onboarding",
  VENDOR_PRODUCTS_LIST: "/products",
  VENDOR_ADD_PRODUCT: "/product/add",
  VENDOR_DASHBOARD: "/vendor/dashboard",
  VENDOR_WALLET: "/vendor/wallet",
  VENDOR_EDIT_PRODUCT: "/product/update/:id",
  VENDOR_ORDERS_LIST: "/orders",
  VENDOR_TICKETS_LIST: "/tickets",
};

export const SUPER_ADMIN_ROUTES = {
  ALL_MEME_SUGGESTIONS_LIST: "/memeSuggestions",
  ALL_VENDORS_LIST: "/vendors",
  BULK_UPLOAD_PRODUCTS: "/bulkUpload",
};

export const generateRoute = ({ path, ...restProps }) => (
  <Route key={path} path={path} element={<ContextWrapper {...restProps} />} />
);

export const ContextWrapper = ({
  Component,
  ContextProvider,
  pageTitle,
  ...props
}) => {
  useEffect(() => {
    document.title = `Meme-T | ${pageTitle}`;
  }, [pageTitle]);

  return ContextProvider ? (
    <ContextProvider>
      <Component {...props} />
    </ContextProvider>
  ) : (
    <Component {...props} />
  );
};

export const RouteAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const token = localStorage.getItem("_t");
  const { currentUser } = useContext(UserContext);

  const authRoute = token ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );

  return _.includes(allowedRoles || USER_ROLES, currentUser?.role) ? (
    <Outlet />
  ) : (
    authRoute
  );
};
