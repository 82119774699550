import { useNavigate } from "react-router-dom";
import _ from "lodash";

const token = localStorage.getItem(`_t`);

const useAuth = () => {
  const navigate = useNavigate();

  const checkUserLoggedIn = () => {
    if (_.isEmpty(token)) {
        navigate(`/login`);
        return false;
    }
  };

  return { checkUserLoggedIn };
};

export default useAuth;
