import React, { useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import { useSnackbar } from "hooks";
import { Collapse } from "@mui/material";
import {
  TextInputField,
  Button,
  Image,
  Loading,
  Snackbar,
  Timer,
  AutocompleteCountryCode,
} from "components";
import { COUNTRY_CODES_LIST } from "constants";
import { UserServices } from "services";
import MemeTLogo from "assets/Meme-T_Login.png";
import {
  encodeDecodeData,
  auth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "utils";
import "./index.css";
import "./mobile.css";
import "./tablet.css";
import "./laptop.css";

const OTPAuthPage = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const [OTPObject, setOTPObject] = useState({
    isOTPSent: false,
    OTPConfirmation: {},
    timeoutOTP: 0,
  });
  const [firebaseToken, setFirebaseToken] = useState(false);

  const isFirebaseTokenGenerated = useMemo(
    () => !_.isEmpty(firebaseToken),
    [firebaseToken]
  );

  const checkUserExists = async (phoneNumber) =>
    await UserServices.verifyUserByPhone(phoneNumber);

  const handleSendOTP = async (phoneNumber) => {
    try {
      auth.languageCode = "en";
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptchaContainer",
        {
          size: "invisible",
        },
        auth
      );
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window.recaptchaVerifier
      );
      setOTPObject({
        OTPConfirmation: confirmationResult,
        isOTPSent: true,
        timeoutOTP: 60,
      });
      return `An OTP has been sent at ${phoneNumber}`;
    } catch (err) {
      return `Error occurred during sending OTP to ${phoneNumber}`;
    }
  };

  const verifyOTPCode = async (otpCode, setSubmitting) => {
    try {
      setSubmitting(true);
      const verificationResponse = await OTPConfirmation.confirm(otpCode);
      setSubmitting(false);

      if (_.isEmpty(verificationResponse)) {
        snackbar.showMessage({
          content: `Invalid OTP`,
        });
        return;
      }
      setFirebaseToken(verificationResponse?._tokenResponse?.idToken);
      snackbar.showMessage({
        content: `OTP verified successfully`,
      });
    } catch (err) {
      snackbar.showMessage({
        content: `Error ocurred during Firebase OTP verification`,
      });
    }
  };

  const sendOTPToPhone = async (phoneNumber, setSubmitting) => {
    setSubmitting(true);
    const encodedPhone = encodeDecodeData(phoneNumber, "encode");
    try {
      const userExists = await checkUserExists(encodedPhone);
      if (!userExists) {
        setSubmitting(false);
        snackbar.showMessage({
          content: `No account linked with ${phoneNumber}`,
        });
        return;
      }
      const msg = await handleSendOTP(phoneNumber);
      setSubmitting(false);
      snackbar.showMessage({
        content: msg,
      });
    } catch (err) {
      console.error(`Error`, err);
    }
  };

  const changeUserPassword = async (password, setSubmitting) => {
    setSubmitting(true);
    const payload = {
      firebaseToken,
      password,
    };
    const { msg } = await UserServices.changeUserPassword(payload);
    setSubmitting(false);
    snackbar.showMessage({
      content: msg,
      handleClose: () => navigate(`/login`),
    });
  };

  const handleResendOTP = async (phoneNumber, setSubmitting) => {
    await window.recaptchaVerifier.clear();
    await window.recaptchaVerifier.recaptcha.reset();
    const msg = await handleSendOTP(phoneNumber);
    setSubmitting(false);
    snackbar.showMessage({
      content: msg,
    });
  };

  const { OTPConfirmation, isOTPSent, timeoutOTP } = OTPObject;
  const labelSent = isOTPSent ? "Verify OTP" : "Send OTP";
  const formHeader = !isFirebaseTokenGenerated ? labelSent : "Change Password";

  return (
    <Formik
      initialValues={{
        countryCode: COUNTRY_CODES_LIST[0],
        phoneNumber: "",
        otpCode: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={yup.object({
        countryCode: yup.object().nullable(),
        phoneNumber: yup
          .string()
          .min(10, "Should be exactly 10 digits")
          .max(10, "Should be exactly 10 digits")
          .required("Phone number is required"),
        otpCode: isOTPSent
          ? yup
              .string()
              .min(6, "Should be exactly 6 digits")
              .max(6, "Should be exactly 6 digits")
              .required("OTP is mandatory")
          : yup.string(),
        password: isFirebaseTokenGenerated
          ? yup
              .string()
              .min(6, "Should contain minimum 6 characters")
              .required("Password is mandatory")
          : yup.string(),
        confirmPassword: isFirebaseTokenGenerated
          ? yup
              .string()
              .oneOf([yup.ref("password"), null], "Passwords must match")
          : yup.string(),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (
        { countryCode, phoneNumber, otpCode, password },
        { setSubmitting }
      ) => {
        if (!isFirebaseTokenGenerated) {
          if (!isOTPSent) {
            await sendOTPToPhone(
              `+${countryCode?.countryCodeVal}${phoneNumber}`,
              setSubmitting
            );
          } else {
            await verifyOTPCode(otpCode, setSubmitting);
          }
        } else {
          await changeUserPassword(password, setSubmitting);
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        errors,
        setFieldValue,
        isSubmitting,
        setSubmitting,
      }) => {
        return (
          <>
            {isSubmitting && <Loading />}
            <Snackbar {...snackbar} />
            <div className="div__OTPAuth-container">
              <form className="div__OTPAuth-formContainer">
                <Link to="/" className="lnkLogo">
                  <Image className="imgLogo" imageURL={MemeTLogo} />
                </Link>
                <div className="div__OTPAuth-form">
                  <span className="lblForgotPassword">Forgot Password?</span>
                  {!isFirebaseTokenGenerated && (
                    <div className="div__OTPAuth-formContainer-verifyPhone">
                      {!isOTPSent && (
                        <div className="div__phoneNoWithCC">
                          <AutocompleteCountryCode
                            className="div__OTPAuthForm-Auth"
                            inputLabel="Code"
                            inputValue={values.countryCode}
                            handleChange={(_ev, val) =>
                              setFieldValue("countryCode", val || {})
                            }
                            handleBlur
                            errorText={
                              touched.countryCode && errors.countryCode
                            }
                          />
                          <TextInputField
                            name="phoneNumber"
                            inputType="number"
                            labelText="Phone Number"
                            placeholder="Enter your 10-digit phone number"
                            inputValue={values.phoneNumber}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errorText={
                              touched.phoneNumber && errors.phoneNumber
                            }
                          />
                        </div>
                      )}
                      <Collapse in={isOTPSent} timeout="auto" unmountOnExit>
                        <div className="div__OTPAuth-formContainer-OTPMessage">
                          <div className="lblPhoneNumber">
                            +{values.countryCode?.countryCodeVal}-
                            {values.phoneNumber}
                            <p
                              onClick={() => window.location.reload(false)}
                              className="lblChangePhoneNumber"
                            >
                              Change
                            </p>
                          </div>
                          <p className="lblOTPMessage">
                            To verify we have sent an OTP to your registered
                            mobile number. Please enter it below to complete
                            verification.
                          </p>
                        </div>
                        <TextInputField
                          name="otpCode"
                          inputType="number"
                          labelText="OTP Code"
                          placeholder="Enter 6 digit OTP Code"
                          inputValue={values.otpCode}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errorText={touched.otpCode && errors.otpCode}
                        />
                      </Collapse>
                      {isOTPSent && (
                        <div className="div__resendOTP">
                          <p
                            className="lnkResendOTP"
                            onClick={() =>
                              handleResendOTP(values.phoneNumber, setSubmitting)
                            }
                          >
                            Resend OTP
                          </p>
                          <Timer
                            timeout={timeoutOTP}
                            className="lblOTPTimeout"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <Collapse
                    in={isFirebaseTokenGenerated}
                    timeout="auto"
                    unmountOnExit
                    className="div__OTPAuth-formContainer-changePassword"
                  >
                    <TextInputField
                      name="password"
                      inputType="password"
                      labelText="New Password"
                      obscureText
                      inputValue={values.password}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errorText={touched.password && errors.password}
                    />
                    <TextInputField
                      name="confirmPassword"
                      inputType="password"
                      labelText="Confirm Password"
                      obscureText
                      inputValue={values.confirmPassword}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errorText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                    />
                  </Collapse>
                  <Button
                    id="btnInput"
                    className="btnActionOTP"
                    onClick={handleSubmit}
                  >
                    {formHeader}
                  </Button>
                  <div id="recaptchaContainer" />
                </div>
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default OTPAuthPage;
