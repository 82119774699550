import React, { useState, useEffect, useContext } from "react";
import {
  GridDisplay,
  Loading,
  CustomDrawer,
  ActionIcon,
  CustomMenu,
} from "components";
import { SearchFilters } from "pages";
import { getGridDisplayConfig } from "displayConfigs";
import { UserContext, SearchContext } from "contexts";
import { SORT_OPTIONS } from "constants";
import { useAuth } from "hooks";
import {
  generateQueryParamsFromObject,
  applyDebounce,
  encodeDecodeData,
} from "utils";
import "./index.css";
import "./mobile.css";
import "./tablet.css";
import _ from "lodash";

const SearchProductsPage = () => {
  const { checkUserLoggedIn } = useAuth();
  const { currentUser, modifyCurrentUserWishlist } = useContext(UserContext);
  const { searchFilter, modifySortFilters } = useContext(SearchContext);
  const [productsList, setProductsList] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortMenu, setSortMenu] = useState(null);

  const productGridConfig = getGridDisplayConfig("SearchProducts", {
    onAddToWishlist: ({ _id, inWishlist }) => {
      checkUserLoggedIn();
      setProductsList((prevList) => {
        const isSuccess = modifyCurrentUserWishlist(_id, inWishlist);
        return prevList.map((prod) => {
          if (prod._id === _id) {
            return {
              ...prod,
              inWishlist: isSuccess && !inWishlist,
            };
          }
          return prod;
        });
      });
    },
  });

  const handleFilterSort = (sortVal) => {
    setSortMenu(null);
    if (!_.isEmpty(sortVal)) {
      const [sortBy, orderBy] = sortVal?.split(";");
      modifySortFilters(sortBy, orderBy);
    }
  };

  const searchProducts = async () => {
    setLoading(true); // Set loading true when search filter changes
    const wishlist = (currentUser?.wishlist || []).join(";");
    try {
      const query = generateQueryParamsFromObject({
        ...searchFilter,
        _w: !_.isEmpty(wishlist) && encodeDecodeData(wishlist, "encode"),
      });
      const res = await productGridConfig.fetchListData(query);
      setLoading(false);
      setProductsList(res);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const delayedSearchProducts = applyDebounce(searchProducts, 500);

  useEffect(() => {
    searchFilter && delayedSearchProducts();
  }, [searchFilter]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div
          className={`div__searchProducts-container ${
            isDrawerOpen && "div__searchProducts-container-drawerOpen"
          }`}
        >
          <div className="div__searchProducts-results">
            <div className="div__searchProducts-header">
              <div className="div__searchProducts-header-left">
                <ActionIcon
                  type="filter"
                  iconClassName="iconFilters"
                  onClick={() => setIsDrawerOpen(true)}
                />
                <span className="lblSearchResults">Search Results</span>
              </div>
              <ActionIcon
                type="sort"
                iconClassName="iconSort"
                id="sortMenu"
                aria-controls="sortAnchorMenu"
                aria-haspopup="true"
                aria-expanded={Boolean(sortMenu)}
                onClick={({ currentTarget }) => setSortMenu(currentTarget)}
              />
            </div>
            <GridDisplay
              className="div__searchProducts-results-list"
              config={productGridConfig}
              loading={loading}
              dataList={productsList}
            />
          </div>
        </div>
      )}
      <CustomDrawer
        direction="left"
        className="drawer__searchFilters"
        isOpen={isDrawerOpen}
        onCloseDrawer={() => setIsDrawerOpen(false)}
      >
        <div className="div__searchProducts-filters">
          <p className="lblFilters">Filters</p>
          <SearchFilters />
        </div>
      </CustomDrawer>
      <CustomMenu
        anchor={sortMenu}
        id="sortMenu"
        anchorId="sortAnchorMenu"
        header={<p className="lblSortMenuHeader">Sort</p>}
        optionsList={SORT_OPTIONS}
        renderOption={({ label, subLabel }) => (
          <div className="div__searchProducts-sortMenu-option">
            <p>{label}</p>
            <p>{subLabel}</p>
          </div>
        )}
        handleClose={(val) => handleFilterSort(val)}
      />
    </>
  );
};

export default SearchProductsPage;
