import { VendorServices } from "services";

const VENDOR_TRANSACTION_CONFIG = {
  screenName: "Transaction",
  collapsibleRow: true,
  columnHeaders: [
    { label: "Credit/Debit", key: "actions", type: "action" },
    { label: "Transaction ID", key: "_id", type: "string" },
    { label: "Order ID", key: "receipt", type: "string" },
    { label: "Date", key: "createdAt", type: "dateTime" },
    { label: "Amount", key: "transactionAmount", type: "currency" },
  ],
  sortTableViaColumn: (headerVal) => {
    console.log(`Sorting via ${headerVal}`);
  },
  fetchListData: VendorServices.fetchTransactionsList,
  dataKey: "transactions",
  dataUnavailableMessage: `No data to display`,
};

export default VENDOR_TRANSACTION_CONFIG;
