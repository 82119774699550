import React, { useState } from "react";
import _ from "lodash";
import {
  ActionIcon,
  PaginatedTable,
  TextField,
  DateTimePickerInput,
  DropdownInput,
  CustomDrawer,
} from "components";
import { STATE_CODES } from "constants";
import { getTableDisplayConfig } from "displayConfigs";
import { generateDropdownOptions } from "utils";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const VendorsListPage = () => {
  const [selectedVendor, setSelectedVendor] = useState();
  const [searchFilters, setSearchFilters] = useState();

  const vendorConfig = getTableDisplayConfig("Vendor", {
    actions: (rowVal) => (
      <>
        <ActionIcon
          type="view"
          iconClassName="listIcon"
          onClick={() => setSelectedVendor(rowVal)}
        />
      </>
    ),
  });

  const handleSearchFiltersChange = (key, value) => {
    setSearchFilters({
      ...searchFilters,
      [key]: value,
    });
  };

  const { header } = vendorConfig;
  const { searchTerm, fromDate, toDate, location } = searchFilters || {};

  return (
    <>
      <div className="admin__vendorsList">
        {header}
        <div className="admin__vendorsList__filters">
          <div className="div__searchInput">
            <TextField
              className="admin__vendorsList__searchInput"
              placeholder="Search by Vendor name"
              value={searchTerm || ``}
              onChange={({ target: { value } }) =>
                handleSearchFiltersChange("searchTerm", value)
              }
            />
          </div>
          <div className="div_Filters">
            <DateTimePickerInput
              inputType="Date"
              className="admin__vendorsList__fromDate"
              placeholder="From"
              dateValue={fromDate || null}
              handleChange={({ _d }) =>
                handleSearchFiltersChange("fromDate", _d.toISOString())
              }
            />
            <DateTimePickerInput
              inputType="Date"
              className="admin__vendorsList__toDate"
              dateValue={toDate || null}
              placeholder="To"
              handleChange={({ _d }) =>
                handleSearchFiltersChange("toDate", _d.toISOString())
              }
            />
            <DropdownInput
              className="admin__vendorsList__status"
              placeholder="State/UT"
              optionsList={generateDropdownOptions(_.keys(STATE_CODES))}
              selectedOption={location || "State/UT"}
              onSelect={({ target: { value } }) =>
                handleSearchFiltersChange("status", value)
              }
            />
          </div>
        </div>
        <PaginatedTable config={vendorConfig} queryFilters={searchFilters} />
        <CustomDrawer
          direction="bottom"
          className="admin__vendorsList__memeSuggestionDrawer--view"
          isOpen={!_.isUndefined(selectedVendor)}
          onCloseDrawer={() => setSelectedVendor(undefined)}
        >
          {selectedVendor && <></>}
        </CustomDrawer>
      </div>
    </>
  );
};

export default VendorsListPage;
