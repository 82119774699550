import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "contexts";
import { useSnackbar } from "hooks";
import { Button, Loading, Snackbar, Image } from "components";
import { UserServices } from "services";
import MemeTLogo from "assets/Meme-T-Logo.png";
import AddressItem from "./AddressItem";
import AddressForm from "./AddressForm";
import "./index.css";
import _ from "lodash";

const AddressManagement = ({ onChangeAddress }) => {
  const snackbar = useSnackbar();
  const { currentUser, modifyCurrentUser } = useContext(UserContext);
  const [addresses, setAddresses] = useState({
    list: [],
    selectedAddress: {},
    editAddress: {},
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUserAddressList();
  }, []);

  const handleSelectAddress = () => {
    modifyCurrentUser({ ...currentUser, address: selectedAddress });
    onChangeAddress();
  };

  const fetchUserAddressList = async () => {
    setLoading(true);
    try {
      const { addressList } = await UserServices.fetchAddressList(
        `customerId=${currentUser?._id}`
      );
      setAddresses({
        ...addresses,
        list: addressList,
        selectedAddress: addressList?.find(({ isLastUsed }) => isLastUsed) || currentUser?.address,
      });
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const onEditAddress = (addressObj) => {
    setAddresses({
      ...addresses,
      editAddress: addressObj,
    });
  };

  const onDeleteAddress = async (addressId) => {
    setLoading(true);
    try {
      const { msg } = await UserServices.deleteSelectedAddress(addressId);
      setLoading(false);
      setAddresses((prevAddress) => {
        const newList = prevAddress?.list?.filter(
          ({ _id }) => _id !== addressId
        );
        return {
          ...prevAddress,
          list: newList,
          selectedAddress: !_.isEmpty(newList) ? newList[0] : {},
        };
      });
      snackbar.showMessage({
        content: msg,
      });
    } catch (err) {
      setLoading(false);
      snackbar.showMessage({
        content: err,
      });
    }
  };

  const { list, selectedAddress, editAddress } = addresses;

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <div className="manageAddress">
        <div className="manageAddress__header">
          <Image imageURL={MemeTLogo} className="manageAddress__header--logo" />
          <div className="manageAddress__header--right">
            <p className="manageAddress__header--right-memeT">Meme - T</p>
            <p className="manageAddress__header--right-address">
              Delivery Address Details
            </p>
          </div>
        </div>
        <div className="manageAddress__content">
          <div className="manageAddress_list-container">
            <div className="manageAddress__list">
              {list?.map((address) => (
                <AddressItem
                  key={address?._id}
                  address={address}
                  isSelected={address?._id === selectedAddress?._id}
                  onSelectAddress={(id) =>
                    setAddresses({
                      ...addresses,
                      selectedAddress: addresses?.list.find(
                        ({ _id }) => id === _id
                      ),
                    })
                  }
                  onEditAddress={(addressItem) => onEditAddress(addressItem)}
                  onDeleteAddress={(addressId) => onDeleteAddress(addressId)}
                />
              ))}
            </div>
            <Button
              id="btnInput"
              className="manageAddress__list-item-confirmAddress"
              disableRipple
              onClick={handleSelectAddress}
            >
              CONFIRM
            </Button>
          </div>
          <div className="manageAddress__form">
            <AddressForm
              addressSelected={editAddress}
              onModifySuccess={fetchUserAddressList}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressManagement;
