export const VENDOR_PRIMARY_INPUTS = [
  {
    name: "email",
    inputType: "email",
    labelText: "Your Email",
    disabled: true
  },
  {
    name: "password",
    inputType: "password",
    labelText: "Your Password",
  },
];

export const VENDOR_ENTITY_TYPES = ["Individual", "Firm"];

export const VENDOR_HEARD_ABOUT_US_OPTIONS = [
  "Search Engine",
  "Social Media",
  "Advertisements",
  "Word Of Mouth",
  "Public Events",
  "Newspaper Ads",
];

export const VENDOR_SECONDARY_INPUTS = [
  {
    name: "confirmPassword",
    inputType: "password",
    labelText: "Confirm Password",
  },
];

export const VENDOR_COMPANY_PRIMARY_INPUTS = [
  {
    name: "name",
    inputType: "text",
    labelText: "Company Name",
    disabled: true
  },
  {
    name: "GSTIN_Number",
    inputType: "text",
    labelText: "GSTIN",
  },
];

export const VENDOR_COMPANY_SECONDARY_INPUTS = [
  {
    name: "ownerName",
    inputType: "text",
    labelText: "Owner Name",
  },
];

export const VENDOR_ADDRESS_LINE_INPUTS = [
  {
    name: "firstLine",
    inputType: "text",
    labelText: "Address Line 1",
  },
  {
    name: "secondLine",
    inputType: "text",
    labelText: "Address Line 2",
  },
];

export const VENDOR_ADDRESS_LOCATION_INPUTS = [
  {
    name: "city",
    inputType: "text",
    labelText: "City",
  },
  {
    name: "state",
    inputType: "text",
    labelText: "State",
  },
  {
    name: "zipCode",
    inputType: "number",
    labelText: "Zip Code",
  },
];

export const VENDOR_MONTHLY_SALES_TARGETS = [
  { label: "100 pcs", value: 100 },
  { label: "100 - 500 pcs", value: 500 },
  { label: "More than 500 pcs", value: 5000 },
];
