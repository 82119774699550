import React, { useState } from "react";
import {
  DetailCard,
  IconDisplay,
  ActionIcon,
  PaginatedTable,
  DateTimePickerInput,
  TextField,
} from "components";
import { getTableDisplayConfig } from "displayConfigs";
import "./index.css";
import "./tablet.css";
import "./mobile.css";

const TransactionsList = () => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const transactionConfig = getTableDisplayConfig("Transaction", {
    actions: ({ transactionType }) => (
      <>
        <ActionIcon
          type={
            transactionType === "CREDIT"
              ? "creditTransaction"
              : "debitTransaction"
          }
          iconClassName={`listIcon--${
            transactionType === "CREDIT" ? "increment" : "decrement"
          }`}
        />
      </>
    ),
  });

  return (
    <>
      <DetailCard
        className="vendorDashboard__gridContainer--vendorTransactions"
        headerClass="vendorDashboard__gridContainer--vendorTransactions-header"
        headerContent={
          <>
            <span className="vendorDashboard__gridContainer--vendorTransactions-header-name">
              <IconDisplay
                type="cash"
                className="vendorDashboard__gridContainer--vendorTransactions-icon"
              />{" "}
              All Transactions
            </span>

            <div className="div__Filters">
              <DateTimePickerInput
                inputType="Date"
                className="vendorDashboard__gridContainer--vendorTransactions-fromDate"
                placeholder="From"
                dateValue={fromDate || null}
                handleChange={({ _d }) => setFromDate(_d.toISOString())}
              />
              <DateTimePickerInput
                inputType="Date"
                className="vendorDashboard__gridContainer--vendorTransactions-toDate"
                dateValue={toDate || null}
                placeholder="To"
                handleChange={({ _d }) => setToDate(_d.toISOString())}
              />
              <TextField
                className="vendorDashboard__gridContainer--vendorTransactions-searchFilter"
                placeholder="Search by Transaction Id"
                value={searchTerm || ``}
                onChange={({ target: { value } }) => setSearchTerm(value)}
              />
            </div>
          </>
        }
        contentClass="vendorDashboard__gridContainer--vendorTransactions-content"
        cardContent={
          <PaginatedTable
            config={transactionConfig}
            queryFilters={{ fromDate, toDate, searchTerm }}
          />
        }
      />
    </>
  );
};

export default TransactionsList;
