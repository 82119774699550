import React, { useRef, useEffect } from "react";
import { displayDateInFormat } from "utils";
import MessageCard from "../MessageCard";
import OrderPrompt from "../OrderPrompt";
import "./index.css";

const ChatList = ({ messages }) => {
  const bottomRef = useRef(null);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  const messagesList = messages?.map(
    ({
      _id,
      isReceived,
      messageContent,
      messageCategory,
      timestamp,
      orderName,
      orderId,
      orderImage,
    }) =>
      messageCategory === `content` ? (
        <MessageCard
          key={_id}
          isReceived={isReceived}
          message={messageContent}
          timestamp={displayDateInFormat(timestamp, "HH:mm")}
        />
      ) : (
        <OrderPrompt
          key={_id}
          orderName={orderName}
          orderId={orderId}
          orderImage={orderImage}
        />
      )
  );

  return (
    <div className="chatList__Container">
      <div className="messageCard__Container">
        {messagesList}
        <div className="refClass" ref={bottomRef} />
      </div>
    </div>
  );
};

export default ChatList;
