export const focusedStyle = {
  borderColor: "#2196f3",
};

export const acceptStyle = {
  borderColor: "#00e676",
};

export const rejectStyle = {
  borderColor: "#ff1744",
};

export const iconStyle = {
  width: "48px",
  margin: "10px",
};
