import IconDisplay from "components/IconDisplay";
import { MemeSuggestionServices } from "services";

const MEME_SUGGESTION_CONFIG = {
  screenName: "MemeSuggestion",
  header: (
    <p className="admin__memeSuggestionsList-header">
      <IconDisplay type="meme" className="" /> All Meme Suggestions
    </p>
  ),
  columnHeaders: [
    { label: "Meme Suggestion", key: "title", type: "string" },
    { label: "Suggested By", key: "consumerName", type: "string" },
    { label: "Language", key: "language", type: "string" },
    { label: "Suggested On", key: "createdAt", type: "date" },
    { label: "Status", key: "activeStatus", type: "string" },
    { label: "Actions", key: "actions", type: "action" },
  ],
  sortTableViaColumn: (headerVal) => {
    console.log(`Sorting via ${headerVal}`);
  },
  fetchListData: MemeSuggestionServices.getMemeSuggestionsList,
  dataKey: "memeSuggestions",
  dataUnavailableMessage: `No data to display`,
};

export default MEME_SUGGESTION_CONFIG;
