import React from "react";
import { TabDisplay } from "components";
import { getTabDisplayConfig } from "displayConfigs";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const MyOrdersPage = () => {
  const tabConfig = getTabDisplayConfig("Orders", []);

  return (
    <>
      <div className="myOrders__container">
        <div className="myOrders__header">{tabConfig.screenName}</div>
        <TabDisplay config={tabConfig} />
      </div>
    </>
  );
};

export default MyOrdersPage;
