import { CUSTOMER_ROUTES } from "./helpers";
import {
  CartPage,
  CheckOut,
  MyMemeSuggestionsPage,
  MyOrdersPage,
  MyTicketsPage,
  WishlistPage,
} from "pages";

const {
  CART,
  CHECKOUT,
  MY_ORDERS,
  MY_TICKETS,
  MY_MEME_SUGGESTIONS,
  MY_WISHLIST,
} = CUSTOMER_ROUTES;

const CUSTOMER_ROUTES_LIST = [
  { path: CART, Component: CartPage, pageTitle: "My Cart" },
  { path: CHECKOUT, Component: CheckOut, pageTitle: "Payment Checkout" },
  { path: MY_ORDERS, Component: MyOrdersPage, pageTitle: "My Orders" },
  { path: MY_TICKETS, Component: MyTicketsPage, pageTitle: "My Tickets" },
  {
    path: MY_MEME_SUGGESTIONS,
    Component: MyMemeSuggestionsPage,
    pageTitle: "My Meme Suggestions",
  },
  { path: MY_WISHLIST, Component: WishlistPage, pageTitle: "My Wishlist" },
];

export default CUSTOMER_ROUTES_LIST;