import React, { useState, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import {
  TextInputField,
  CheckboxInput,
  Button,
  Image,
  Loading,
  Snackbar,
} from "components";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useSnackbar } from "hooks";
import { UserContext } from "contexts";
import { UserServices } from "services";
import { MemeTLogo, FacebookLogo, GoogleLogo } from "./icons";
import "./index.css";
import "./mobile.css";
import "./tablet.css";
import "./laptop.css";
const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const rememberMeChecked = localStorage.getItem("rememberMe");
  const { fetchUserData } = useContext(UserContext);
  const [rememberMe, setRememberMe] = useState(_.isEmpty(rememberMeChecked));
  const fromRoute = location.state?.from?.pathname || "/";

  const initialEmail = localStorage.getItem("rememberMe") || "";

  const loginUserViaGoogleFB = async (url, payload) => {
    // Refactored method for Google/FB Login
    setLoading(true);
    try {
      const { token, tokenExp } = await UserServices.createUpdateUser(
        url,
        payload
      );
      localStorage.setItem("_t", token);
      localStorage.setItem("_tokenExp", tokenExp);
      await fetchUserData();
      navigate(fromRoute);
    } catch (err) {
      snackbar.showMessage({
        content: `Authentication Invalid`,
      });
    }
  };

  const renderFacebookButton = (onClick) => (
    <div className="div__login-form-facebookLogin" onClick={onClick}>
      <img className="imgFacebookLogo" src={FacebookLogo} alt="facebookLogo" />
      <p>Login with Facebook</p>
    </div>
  );

  const renderGoogleLogin = (onClick) => (
    <div className="div__login-form-googleLogin" onClick={onClick}>
      <img src={GoogleLogo} alt="googleLogo" />
      <p>Sign in with Google</p>
    </div>
  );

  const facebookLoginResponse = async (fbResponse) => {
    if (!_.isEmpty(fbResponse)) {
      const { name, email, picture, id, accessToken, expiresIn } = fbResponse;
      const fbPayload = {
        name,
        email,
        profilePic: picture.data.url,
        facebookId: id,
        token: accessToken,
        tokenExp: expiresIn,
      };

      loginUserViaGoogleFB(`create/Facebook`, fbPayload);
    } else {
      snackbar.showMessage({
        content: `Some error occurred during Facebook authentication`,
      });
    }
  };

  const onGoogleLoginSuccess = async (googleResp) => {
    // Handling Google Auth Response
    if (!_.isEmpty(googleResp)) {
      const { familyName, givenName, email, imageUrl, googleId } =
        googleResp?.profileObj;
      const googlePayload = {
        name: `${givenName} ${familyName}`,
        email,
        profilePic: imageUrl,
        googleId,
      };

      loginUserViaGoogleFB(`create/Google`, googlePayload);
    } else {
      snackbar.showMessage({
        content: `Some error occurred during Google authentication`,
      });
    }
  };

  const onGoogleLoginError = () =>
    snackbar.showMessage({
      content: `Some error occurred during Google authentication`,
    });

  return (
    <Formik
      initialValues={{
        email: initialEmail,
        password: "",
      }}
      validationSchema={yup.object({
        email: yup
          .string()
          .nullable()
          .email("Email format is invalid")
          .required("Email is required"),
        password: yup.string().required("Password is required"),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values) => {
        const payload = {
          email: values.email,
          password: values.password,
        };

        setLoading(true);

        try {
          const { token, tokenExp } = await UserServices.loginUser(payload);
          setLoading(false);
          localStorage.setItem("_t", token);
          localStorage.setItem("_tokenExp", tokenExp);
          if (rememberMe) {
            localStorage.setItem("rememberMe", values.email);
          } else {
            localStorage.removeItem("rememberMe");
          }
          await fetchUserData();
          navigate(fromRoute);
        } catch (err) {
          setLoading(false);
          snackbar.showMessage({
            content: err,
          });
        }
      }}
    >
      {(formikProps) => {
        return (
          <>
            <Snackbar {...snackbar} />
            {loading && <Loading />}
            <div className="div__login-container">
              <form
                onSubmit={formikProps.handleSubmit}
                className="div__login-formContainer"
              >
                <Link to="/" className="lnkLogo">
                  <Image className="imgLogo" imageURL={MemeTLogo} />
                </Link>
                <div className="div__login-form">
                  <span className="lblSignIn">Sign In</span>
                  <TextInputField
                    name="email"
                    inputType="email"
                    labelText="Email"
                    inputValue={formikProps.values.email}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.email && formikProps.errors.email
                    }
                  />
                  <TextInputField
                    name="password"
                    inputType="password"
                    labelText="Password"
                    obscureText
                    forgotURL={`/forgotPassword`}
                    inputValue={formikProps.values.password}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    errorText={
                      formikProps.touched.password &&
                      formikProps.errors.password
                    }
                  />
                  <CheckboxInput
                    labelText="Remember Me"
                    isChecked={rememberMe}
                    handleChange={() => setRememberMe(!rememberMe)}
                  />
                  <Button
                    buttonText="Sign In"
                    id="btnInput"
                    className="btnSignIn"
                    disableRipple
                    onClick={formikProps.handleSubmit}
                  >
                    Sign In
                  </Button>
                  <div className="div__login-accountRegister">
                    <div className="dividerLine" />
                    <span className="div__login-accountRegister-label">
                      New to Meme-T?
                    </span>
                    <div className="dividerLine" />
                  </div>
                  <Button
                    id="btnInput"
                    size="large"
                    disabled={formikProps.isSubmitting}
                    className="btnRegister"
                    onClick={() => navigate(`/register`)}
                  >
                    Create New Account
                  </Button>
                  <div className="div__login-socialMedia">
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      autoLoad={false}
                      render={({ onClick }) => renderGoogleLogin(onClick)}
                      buttonText="Login"
                      onSuccess={onGoogleLoginSuccess}
                      onFailure={onGoogleLoginError}
                      cookiePolicy={"single_host_origin"}
                    />
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                      autoLoad={false}
                      render={({ onClick }) => renderFacebookButton(onClick)}
                      fields="name,email,picture"
                      callback={facebookLoginResponse}
                    />
                  </div>
                </div>
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default LoginPage;
