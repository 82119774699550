import React, { useContext, useState } from "react";
import _ from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  FileUpload,
  Loading,
  Snackbar,
  Image,
  Button,
  CircularProgress,
  RadioInput,
  TextInputField,
  DropdownInput,
  AutoCompleteInput,
} from "components";
import {
  generateInputFields,
  displayDriveImage,
  generateDropdownOptions,
} from "utils";
import {
  VENDOR_PRIMARY_INPUTS,
  VENDOR_SECONDARY_INPUTS,
  VENDOR_ADDRESS_LINE_INPUTS,
  FILE_TYPES,
  STATE_CODES,
  VENDOR_COMPANY_PRIMARY_INPUTS,
  VENDOR_COMPANY_SECONDARY_INPUTS,
  VENDOR_MONTHLY_SALES_TARGETS,
  VENDOR_ENTITY_TYPES,
  VENDOR_HEARD_ABOUT_US_OPTIONS,
  PASSWORD_VALIDATION_FORMATS,
} from "constants";
import { VendorContext } from "contexts";
import { useSnackbar } from "hooks";
import VendorBasicDetailsImage from "assets/Vendor/VendorBasicDetails.svg";
import AddProfilePic from "assets/ProfilePic.png";
import "./index.css";
import "./mobile.css";

const { IMAGE_JPG, IMAGE_JPEG, IMAGE_PNG } = FILE_TYPES;

const VendorBasicDetails = () => {
  const snackbar = useSnackbar();
  const { currentVendor, setCurrentVendor } = useContext(VendorContext);
  const [profilePic, setProfilePic] = useState("");
  const [imageLoading, setImageLoading] = useState(false);

  const profilePicImage = profilePic
    ? displayDriveImage(profilePic)
    : AddProfilePic;

  const handleAutocompleteInputChange = (formikProps, key, val) => {
    formikProps.setFieldValue(key, val);
    if (_.isEmpty(formikProps.values[key])) {
      formikProps.setErrors({
        ...formikProps.errors,
        [key]: true,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        name: currentVendor?.name || "",
        email: currentVendor?.email || "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        ownerName: "",
        GSTIN_Number: "",
        firstLine: "",
        secondLine: "",
        state: "",
        city: "",
        zipCode: "",
        heardAboutUs: "",
        monthlySalesTarget: 0,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Company Name is mandatory"),
        email: Yup.string()
          .email("Email is invalid")
          .required("Email is mandatory"),
        password: Yup.string()
          .min(6, "Password must be at least 6 characters")
          .required("Password is mandatory")
          .test(
            "password",
            "Password should contain atleast one capital letter",
            (val) => PASSWORD_VALIDATION_FORMATS.CAPITALS.test(val)
          )
          .test(
            "password",
            "Password should contain atleast one digit",
            (val) => PASSWORD_VALIDATION_FORMATS.NUMBERS.test(val)
          )
          .test(
            "password",
            `Password should contain atleast one of the special characters ${PASSWORD_VALIDATION_FORMATS.SPECIAL_CHARACTERS_TEXT}`,
            (val) => PASSWORD_VALIDATION_FORMATS.SPECIAL_CHARACTERS.test(val)
          ),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Passwords must match"
        ),
        ownerName: Yup.string().required("Owner Name is mandatory"),
        GSTIN_Number: Yup.string(),
        firstLine: Yup.string().required("Address Line 1 is mandatory"),
        secondLine: Yup.string(),
        state: Yup.string().required("State is mandatory"),
        city: Yup.string().required("City is mandatory"),
        zipCode: Yup.string()
          .min(6, "Should contain exactly 6 digits")
          .max(6, "Should contain exactly 6 digits")
          .required("PIN Code is mandatory"),
        heardAboutUs: Yup.string(),
        monthlySalesTarget: Yup.number().required(""),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (
        {
          name,
          email,
          password,
          firstLine,
          secondLine,
          city,
          state,
          zipCode,
          ownerName,
          GSTIN_Number,
          entityType,
          heardAboutUs,
          monthlySalesTarget,
        },
        { setSubmitting }
      ) => {
        const payload = {
          name,
          email,
          password,
          profilePic,
          address: {
            recipientName: name,
            firstLine,
            secondLine,
            city,
            state,
            zipCode,
          },
          ownerName,
          GSTIN_Number,
          entityType,
          heardAboutUs,
          monthlySalesTarget: parseInt(monthlySalesTarget, 10),
        };
        setSubmitting(true);
        try {
          setCurrentVendor({
            ...currentVendor,
            ...payload,
            role: 200,
            onboardingStep: currentVendor?.onboardingStep + 1,
          });
          setSubmitting(false);
        } catch (err) {
          setSubmitting(false);
          snackbar.showMessage({
            content: err,
          });
        }
      }}
    >
      {(formikProps) => (
        <>
          {formikProps.isSubmitting && <Loading />}
          <Snackbar {...snackbar} />
          <div className="vendorOnboarding__basicDetails-formContainer">
            <div className="vendorOnboarding__basicDetails-formContainer-image">
              <FileUpload
                isSingle
                htmlFor="uploadCompanyLogo"
                acceptFileTypes={[IMAGE_JPEG, IMAGE_JPG, IMAGE_PNG].join(", ")}
                onLoading={(loadVal) => setImageLoading(loadVal)}
                onUploadSuccess={(resURL) => setProfilePic(resURL)}
              >
                <div className="vendorOnboarding__basicDetails-formContainer-image--left">
                  <p className="vendorOnboarding__basicDetails-formContainer-image--left-header">
                    Company Logo
                  </p>
                  {imageLoading ? (
                    <CircularProgress className="loadingProgress" />
                  ) : (
                    <Image
                      id="uploadCompanyLogo"
                      className="uploadProfilePic"
                      imageURL={profilePicImage}
                      alt="profilePic"
                    />
                  )}
                </div>
              </FileUpload>
              <Image
                imageURL={VendorBasicDetailsImage}
                className="vendorOnboarding__basicDetails-formContainer-image--right"
              />
            </div>
            <div className="vendorOnboarding__basicDetails-formContainer-details">
              <div className="vendorOnboarding__basicDetails-formContainer-details--left">
                {generateInputFields(formikProps, VENDOR_PRIMARY_INPUTS)}
              </div>
              <div className="vendorOnboarding__basicDetails-formContainer-details--right">
                {generateInputFields(formikProps, VENDOR_SECONDARY_INPUTS)}
              </div>
            </div>
            <p className="vendorOnboarding__basicDetails-formContainer-section">
              Company
            </p>
            <div className="vendorOnboarding__basicDetails-formContainer-companyDetails">
              <div className="vendorOnboarding__basicDetails-formContainer-companyDetails--left">
                {generateInputFields(
                  formikProps,
                  VENDOR_COMPANY_PRIMARY_INPUTS
                )}
              </div>
              <div className="vendorOnboarding__basicDetails-formContainer-companyDetails--right">
                {generateInputFields(
                  formikProps,
                  VENDOR_COMPANY_SECONDARY_INPUTS
                )}
                <DropdownInput
                  name="entityType"
                  className="vendorOnboarding__basicDetails-formContainer-companyDetails--right-entityType"
                  labelText="Entity Type"
                  optionsList={generateDropdownOptions(VENDOR_ENTITY_TYPES)}
                  selectedOption={formikProps.values.entityType}
                  onSelect={formikProps.handleChange}
                />
              </div>
            </div>
            <p className="vendorOnboarding__basicDetails-formContainer-section">
              Address
            </p>
            <div className="vendorOnboarding__basicDetails-formContainer-address">
              <div className="vendorOnboarding__basicDetails-formContainer-addressLine">
                {generateInputFields(formikProps, VENDOR_ADDRESS_LINE_INPUTS)}
              </div>
              <div className="vendorOnboarding__basicDetails-formContainer-addressLocation">
                <TextInputField
                  name="city"
                  inputType="text"
                  labelText="City"
                  inputValue={formikProps.values.city}
                  handleChange={formikProps.handleChange}
                  handleBlur={formikProps.handleBlur}
                  errorText={
                    formikProps.touched.city && formikProps.errors.city
                  }
                />
                <AutoCompleteInput
                  name="state"
                  className="vendorOnboarding__basicDetails-formContainer-addressLocation-state"
                  optionsList={_.keys(STATE_CODES)}
                  labelText="State"
                  placeholder="Type state here"
                  value={formikProps.values.state}
                  handleChange={(_ev, val) =>
                    handleAutocompleteInputChange(formikProps, "state", val)
                  }
                  handleBlur={() =>
                    formikProps.setTouched({
                      ...formikProps.touched,
                      state: true,
                    })
                  }
                  errorText={
                    formikProps.touched.state && formikProps.errors.state
                  }
                />
                <TextInputField
                  name="zipCode"
                  inputType="number"
                  labelText="Zip Code"
                  inputValue={formikProps.values.zipCode}
                  handleChange={formikProps.handleChange}
                  handleBlur={formikProps.handleBlur}
                  errorText={
                    formikProps.touched.zipCode && formikProps.errors.zipCode
                  }
                />
              </div>
            </div>
            <div className="vendorOnboarding__basicDetails-formContainer-heardAboutUs">
              <DropdownInput
                name="heardAboutUs"
                className="vendorOnboarding__basicDetails-formContainer-heardAboutUsText"
                labelText="How did you hear about us?"
                optionsList={generateDropdownOptions(
                  VENDOR_HEARD_ABOUT_US_OPTIONS
                )}
                selectedOption={formikProps.values.heardAboutUs}
                onSelect={formikProps.handleChange}
              />
            </div>
            <RadioInput
              name="monthlySalesTarget"
              className="vendorOnboarding__basicDetails-formContainer-monthlySalesTarget"
              labelClass="vendorOnboarding__basicDetails-formContainer-monthlySalesTarget-label"
              inputClass="vendorOnboarding__basicDetails-formContainer-monthlySalesTarget-option"
              inputLabel="What are your sales target per month?"
              selectedOption={formikProps.values.monthlySalesTarget}
              optionsList={VENDOR_MONTHLY_SALES_TARGETS}
              handleChange={formikProps.handleChange}
            />
            <Button
              id="btnInput"
              className="vendorOnboarding__basicDetails-formContainer-submit"
              onClick={formikProps.handleSubmit}
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};

export default VendorBasicDetails;
