import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button, Radio, Collapse, TextInputField } from "components";
import "./index.css";

const SavedPayments = ({ savedPayments, paymentAmount, onPayment }) => {
  const [selectedSavedPayment, setSelectedSavedPayment] = useState({});
  const [cardCVV, setCardCVV] = useState("");

  useEffect(() => {
    setSelectedSavedPayment(_.first(savedPayments));
  }, [savedPayments]);

  const errorMessage =
    selectedSavedPayment?.method !== `card`
      ? ""
      : _.isEmpty(cardCVV)
      ? "Card CVV is mandatory"
      : cardCVV.length !== 3
      ? "Card CVV must be exactly 3 digits"
      : "";

  const handleSavedPayment = () =>
    onPayment({
      method: selectedSavedPayment?.method,
      "card[cvv]": cardCVV,
      token: selectedSavedPayment?.token,
    });

  const renderSavedPaymentMethod = (savedPaymentData) => (
    <div key={savedPaymentData?.id} className="savedPayments__list-method">
      <Radio
        checked={savedPaymentData?.id === selectedSavedPayment?.id}
        onChange={() => setSelectedSavedPayment(savedPaymentData)}
        value={savedPaymentData?.id}
      />
      <div className="savedPayments__list-method-details">
        <p className="savedPayments__list-method-detail--name">
          {savedPaymentData?.card?.name}
        </p>
        <p className="savedPayments__list-method-detail--identity">
          {`${Array(3).fill("xxxx").join("-")}-`}
          {savedPaymentData?.card?.last4}
        </p>
      </div>
    </div>
  );

  return (
    <div class="savedPayments__container">
      <div className="savedPayments__list">
        {_.map(savedPayments, renderSavedPaymentMethod)}
      </div>
      <Collapse
        in={selectedSavedPayment?.method === `card`}
        timeout={500}
        className="savedPayments__list-method-cardCVV"
      >
        <TextInputField
          labelText="Card CVV"
          inputValue={cardCVV}
          obscureText
          handleChange={({ target }) => setCardCVV(target.value)}
          errorText={errorMessage}
        />
      </Collapse>
      <Button onClick={handleSavedPayment} id="button_checkoutPayment">
        Pay {paymentAmount}
      </Button>
    </div>
  );
};

export default SavedPayments;
