import { VENDOR_ROUTES } from "./helpers";
import { InventoryContextProvider, VendorContextProvider } from "contexts";
import {
  AddProductPage,
  OrdersListPage,
  ProductListPage,
  TicketsListPage,
  VendorLoginPage,
  VendorMainDashboard,
  VendorOnboardingLayout,
  VendorWalletDashboard,
} from "pages";

const {
  VENDOR_LOGIN,
  VENDOR_ONBOARDING,
  VENDOR_PRODUCTS_LIST,
  VENDOR_ADD_PRODUCT,
  VENDOR_DASHBOARD,
  VENDOR_WALLET,
  VENDOR_EDIT_PRODUCT,
  VENDOR_ORDERS_LIST,
  VENDOR_TICKETS_LIST,
} = VENDOR_ROUTES;

const VENDOR_ROUTES_LIST = [
  {
    path: VENDOR_LOGIN,
    Component: VendorLoginPage,
    ContextProvider: VendorContextProvider,
    pageTitle: "Vendor Login",
  },
  {
    path: VENDOR_ONBOARDING,
    Component: VendorOnboardingLayout,
    ContextProvider: VendorContextProvider,
    pageTitle: "Vendor Onboarding",
  },
  {
    path: VENDOR_ADD_PRODUCT,
    Component: AddProductPage,
    ContextProvider: InventoryContextProvider,
    pageTitle: "Add Product",
  },
  {
    path: VENDOR_EDIT_PRODUCT,
    Component: AddProductPage,
    ContextProvider: InventoryContextProvider,
    pageTitle: "Edit Product",
  },
  {
    path: VENDOR_DASHBOARD,
    Component: VendorMainDashboard,
    ContextProvider: VendorContextProvider,
    pageTitle: "Vendor Dashboard",
  },
  {
    path: VENDOR_WALLET,
    Component: VendorWalletDashboard,
    ContextProvider: VendorContextProvider,
    pageTitle: "Vendor Wallet",
  },
  {
    path: VENDOR_PRODUCTS_LIST,
    Component: ProductListPage,
    ContextProvider: InventoryContextProvider,
    pageTitle: "Vendor Products",
  },
  {
    path: VENDOR_ORDERS_LIST,
    Component: OrdersListPage,
    pageTitle: "Vendor Orders",
  },
  {
    path: VENDOR_TICKETS_LIST,
    Component: TicketsListPage,
    pageTitle: "Vendor Tickets",
  },
];

export default VENDOR_ROUTES_LIST;