import React from "react";
import _ from "lodash";
import "./index.css";

const ChatQuestionCard = ({ header, optionsList, onSelectOption }) => {
  const renderHelpOption = (title) => (
    <div
      key={title}
      className="chat__help--category"
      onClick={() => onSelectOption(title)}
    >
      {title}
    </div>
  );

  return (
    <div className="chatQuestionCard-container">
      <div className="chatQuestionCard-container-header">{header}</div>
      <div className="chatQuestionCard-container-content">
        {_.map(optionsList, renderHelpOption)}
      </div>
    </div>
  );
};

export default ChatQuestionCard;
