import IconDisplay from "components/IconDisplay";

export const PRIMARY_INPUTS = [
  {
    name: "name",
    iconType: "user",
    inputType: "text",
    labelText: "Your Name",
    icon: <IconDisplay type="person" className="fieldIcon" />,
  },
  {
    name: "email",
    iconType: "mail",
    inputType: "email",
    labelText: "Your Email",
    icon: <IconDisplay type="email" className="fieldIcon" />,
  },
  {
    name: "password",
    iconType: "eye",
    inputType: "password",
    labelText: "Your Password",
    icon: <IconDisplay type="lock" className="fieldIcon" />,
  },
];

export const SECONDARY_INPUTS = [
  // {
  //   name: "phoneNumber",
  //   iconType: "phone",
  //   inputType: "number",
  //   labelText: "Your Phone Number",
  //   icon: <IconDisplay type="phone" className="fieldIcon" />,
  // },
  {
    name: "confirmPassword",
    iconType: "eye",
    inputType: "password",
    labelText: "Confirm Password",
    icon: <IconDisplay type="lock" className="fieldIcon" />,
  },
];

export const ADDRESS_LINE_INPUTS = [
  {
    name: "firstLine",
    inputType: "text",
    labelText: "Address Line 1",
  },
  {
    name: "secondLine",
    inputType: "text",
    labelText: "Address Line 2",
  },
];

export const ADDRESS_LOCATION_INPUTS = [
  {
    name: "city",
    inputType: "text",
    labelText: "City",
  },
  // {
  //   name: "state",
  //   inputType: "text",
  //   labelText: "State",
  // },
  {
    name: "zipCode",
    inputType: "number",
    labelText: "Zip Code",
  },
];

export const USER_ROLES = {
  CUSTOMER: 100,
  VENDOR: 200,
  SUPER_ADMIN: 500,
};

export const PASSWORD_VALIDATION_FORMATS = {
  CAPITALS: new RegExp(/^(?=.*[A-Z]).*$/),
  NUMBERS: new RegExp(/^(?=.*\d).*$/),
  SPECIAL_CHARACTERS: new RegExp(/^(?=.*[!@#$%^&_]).*$/),
  SPECIAL_CHARACTERS_TEXT: `(!, @, #, $, %, ^, &, _)`,
};

export const STATE_CODES = {
  "Andhra Pradesh": 37,
  "Arunachal Pradesh": 12,
  Assam: 18,
  Bihar: 10,
  Chandigarh: 4,
  Chhattisgarh: 22,
  "Dadra and Nagar Haveli": 26,
  "Daman and Diu": 25,
  Delhi: 7,
  Goa: 30,
  Gujarat: 24,
  Haryana: 6,
  "Himachal Pradesh": 2,
  "Jammu and Kashmir": 1,
  Jharkhand: 20,
  Karnataka: 29,
  Kerala: 32,
  Ladakh: 38,
  Lakshadweep: 31,
  "Madhya Pradesh": 23,
  Maharashtra: 27,
  Manipur: 14,
  Meghalaya: 17,
  Mizoram: 15,
  Nagaland: 13,
  Odisha: 21,
  Puducherry: 34,
  Punjab: 3,
  Rajasthan: 8,
  Sikkim: 11,
  "Tamil Nadu": 33,
  Telangana: 36,
  Tripura: 16,
  "Uttar Pradesh": 9,
  Uttarakhand: 5,
  "West Bengal": 19,
};
