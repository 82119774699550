import { SUPER_ADMIN_ROUTES } from "./helpers";
import { MemeSuggestionsListPage, BulkUploadPage, VendorsListPage } from "pages";

const { ALL_MEME_SUGGESTIONS_LIST, ALL_VENDORS_LIST, BULK_UPLOAD_PRODUCTS } = SUPER_ADMIN_ROUTES;

const SUPER_ADMIN_ROUTES_LIST = [
  {
    path: ALL_MEME_SUGGESTIONS_LIST,
    Component: MemeSuggestionsListPage,
    pageTitle: "All Meme Suggestions",
  },
  {
    path: ALL_VENDORS_LIST,
    Component: VendorsListPage,
    pageTitle: "All Vendors",
  },
  {
    path: BULK_UPLOAD_PRODUCTS,
    Component: BulkUploadPage,
    pageTitle: "Bulk Upload",
  },
];

export default SUPER_ADMIN_ROUTES_LIST;