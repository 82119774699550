import React, { useState } from "react";
import _ from "lodash";
import {
  ActionIcon,
  PaginatedTable,
  TextField,
  DateTimePickerInput,
  DropdownInput,
  Snackbar,
  CustomDrawer,
} from "components";
import { ORDER_STATUS_LIST, DELIVERY_STATUS_LIST } from "constants";
import { getTableDisplayConfig } from "displayConfigs";
import { generateDropdownOptions } from "utils";
import { useSnackbar } from "hooks";
import OrderDetail from "pages/Orders/MyOrders/MyOrdersList/OrderDetail";
import "./index.css";
import "./tablet.css";
import "./mobile.css";

const OrdersListPage = () => {
  const snackbar = useSnackbar();
  const [selectedOrder, setSelectedOrder] = useState();
  const [searchFilters, setSearchFilters] = useState();

  const orderConfig = getTableDisplayConfig("Order", {
    actions: (rowVal) => (
      <ActionIcon
        type="view"
        iconClassName="listIcon"
        onClick={() => setSelectedOrder(rowVal)}
      />
    ),
  });

  const handleSearchFiltersChange = (key, value) => {
    setSearchFilters({
      ...searchFilters,
      [key]: value,
    });
  };

  const { header } = orderConfig;
  const { searchTerm, fromDate, toDate, orderStatus, deliveryStatus } =
    searchFilters || {};
  const queryFilters =
    {
      ...searchFilters,
      orderStatusIndex:
        !_.isUndefined(orderStatus) &&
        _.findIndex(ORDER_STATUS_LIST, (item) => _.isEqual(item, orderStatus)),
      deliveryStatusIndex:
        !_.isUndefined(deliveryStatus) &&
        _.findIndex(DELIVERY_STATUS_LIST, (item) =>
          _.isEqual(item, deliveryStatus)
        ),
    } || {};

  return (
    <>
      <Snackbar {...snackbar} />
      <div className="admin__ordersList">
        {header}
        <div className="admin__ordersList__filters">
          <div className="div__searchInput">
            <TextField
              className="admin__ordersList__searchInput"
              placeholder="Search by Order Id or customer name"
              value={searchTerm || ``}
              onChange={({ target }) =>
                handleSearchFiltersChange("searchTerm", target.value)
              }
            />
          </div>
          <div className="div__Filters">
            <DateTimePickerInput
              inputType="Date"
              className="admin__ordersList__fromDate"
              placeholder="From"
              dateValue={fromDate || null}
              handleChange={({ _d }) =>
                handleSearchFiltersChange("fromDate", _d.toISOString())
              }
            />
            <DateTimePickerInput
              inputType="Date"
              className="admin__ordersList__toDate"
              dateValue={toDate || null}
              placeholder="To"
              handleChange={({ _d }) =>
                handleSearchFiltersChange("toDate", _d.toISOString())
              }
            />
            <DropdownInput
              className="admin__ordersList__orderStatus"
              placeholder="Order Status"
              optionsList={generateDropdownOptions(ORDER_STATUS_LIST)}
              selectedOption={orderStatus || "Order Status"}
              onSelect={({ target: { value } }) =>
                handleSearchFiltersChange("orderStatus", value)
              }
            />
            <DropdownInput
              className="admin__ordersList__deliveryStatus"
              optionsList={generateDropdownOptions(DELIVERY_STATUS_LIST)}
              placeholder="Delivery Status"
              selectedOption={deliveryStatus || "Delivery Status"}
              onSelect={({ target: { value } }) =>
                handleSearchFiltersChange("deliveryStatus", value)
              }
            />
          </div>
        </div>
        <PaginatedTable config={orderConfig} queryFilters={queryFilters} />
        <CustomDrawer
          direction="bottom"
          className="admin__ordersList__orderDrawer--view"
          isOpen={!_.isUndefined(selectedOrder)}
          onCloseDrawer={() => setSelectedOrder(undefined)}
        >
          {selectedOrder && (
            <OrderDetail
              orderDetail={selectedOrder}
              isAdmin
              onCloseDrawer={() => setSelectedOrder(undefined)}
            />
          )}
        </CustomDrawer>
      </div>
    </>
  );
};

export default OrdersListPage;
