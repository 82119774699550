export const ORDER_TABS_LIST = [
  { label: "Active Orders", orderStatus: "PAID", deliveryStatus: "" },
  { label: "Delivered", orderStatus: "", deliveryStatus: "DELIVERED" },
  { label: "Refunds", orderStatus: "REFUNDED", deliveryStatus: "" },
  { label: "Cancelled", orderStatus: "CANCELLED", deliveryStatus: "" },
];

export const ORDER_STATUS_LIST = [
  "PAID",
  "CANCELLED",
  "REFUND_REQUEST",
  "REFUND_DECLINED",
  "REFUND_APPROVED",
  "REFUNDED",
];

export const DELIVERY_STATUS_LIST = [
  "DISPATCHED",
  "DELIVERED",
  "PICKUP_REQUEST",
  "PICKUP_CONFIRMED",
  "RETURNED",
];

export const ORDER_STATUS_STEPS = [
  {
    description: "PAID",
    icon: "cash",
    label: "Order Placed",
  },
  {
    description: "CANCELLED",
    icon: "cancelled",
    label: "Order Cancelled",
  },
  // {
  //   description: "REFUND_REQUEST",
  //   icon: "completed",
  //   label: "Refund Requested",
  // },
  // {
  //   description: "REFUND_DECLINED",
  //   icon: "cancelled",
  //   label: "Refund Declined",
  // },
  // {
  //   description: "REFUND_APPROVED",
  //   icon: "cancelled",
  //   label: "Refund Approved",
  // },
  // {
  //   description: "REFUNDED",
  //   icon: "cancelled",
  //   label: "Order Refunded",
  // },
];

export const DELIVERY_STATUS_STEPS = [
  {
    description: "DISPATCHED",
    icon: "shoppingBag",
    label: "Order Dispatched",
  },
  {
    description: "DELIVERED",
    icon: "completed",
    label: "Order Delivered",
  },
  // {
  //   description: "PICKUP_REQUEST",
  //   icon: "completed",
  //   label: "Order Delivered",
  // },
  // {
  //   description: "PICKUP_CONFIRMED",
  //   icon: "localShipping",
  //   label: "Order Delivered",
  // },
  // {
  //   description: "RETURNED",
  //   icon: "completed",
  //   label: "Order Delivered",
  // },
];
