import React, { useState } from "react";
import _ from "lodash";
import {
  ActionIcon,
  PaginatedTable,
  TextField,
  DateTimePickerInput,
  DropdownInput,
  CustomDrawer,
} from "components";
import { getTableDisplayConfig } from "displayConfigs";
import { LANGUAGE_OPTIONS } from "constants";
import MemeSuggestionDetail from "./MemeSuggestionDetail";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const MemeSuggestionsListPage = () => {
  const [selectedMemeSuggestion, setSelectedMemeSuggestion] = useState();
  const [searchFilters, setSearchFilters] = useState();

  const memeSuggestionConfig = getTableDisplayConfig("MemeSuggestion", {
    actions: (rowVal) => (
      <>
        <ActionIcon
          type="view"
          iconClassName="listIcon"
          onClick={() => setSelectedMemeSuggestion(rowVal)}
        />
      </>
    ),
  });

  const handleSearchFiltersChange = (key, value) => {
    setSearchFilters({
      ...searchFilters,
      [key]: value,
    });
  };

  const { header } = memeSuggestionConfig;
  const { searchTerm, fromDate, toDate, category, status } =
    searchFilters || {};

  return (
    <>
      <div className="admin__memeSuggestionsList">
        {header}
        <div className="admin__memeSuggestionsList__filters">
          <div className="div__searchInput">
            <TextField
              className="admin__memeSuggestionsList__searchInput"
              placeholder="Search by Meme Name or consumer name"
              value={searchTerm || ``}
              onChange={({ target }) =>
                handleSearchFiltersChange("searchTerm", target.value)
              }
            />
          </div>
          <div className="div_Filters">
            <DateTimePickerInput
              inputType="Date"
              className="admin__memeSuggestionsList__fromDate"
              placeholder="From"
              dateValue={fromDate || null}
              handleChange={({ _d }) =>
                handleSearchFiltersChange("fromDate", _d.toISOString())
              }
            />
            <DateTimePickerInput
              inputType="Date"
              className="admin__memeSuggestionsList__toDate"
              dateValue={toDate || null}
              placeholder="To"
              handleChange={({ _d }) =>
                handleSearchFiltersChange("toDate", _d.toISOString())
              }
            />
            <DropdownInput
              className="admin__memeSuggestionsList__status"
              placeholder="Meme Status"
              optionsList={[]}
              selectedOption={status || "Meme Status"}
              onSelect={({ target: { value } }) =>
                handleSearchFiltersChange("status", value)
              }
            />
            <DropdownInput
              className="admin__memeSuggestionsList__category"
              optionsList={LANGUAGE_OPTIONS.map((language) => ({
                label: language,
                value: language,
              }))}
              placeholder="Language"
              selectedOption={category || "Language"}
              onSelect={({ target: { value } }) =>
                handleSearchFiltersChange("language", value)
              }
            />
          </div>
        </div>
        <PaginatedTable
          config={memeSuggestionConfig}
          queryFilters={searchFilters}
        />
        <CustomDrawer
          direction="bottom"
          className="admin__memeSuggestionsList__memeSuggestionDrawer--view"
          isOpen={!_.isUndefined(selectedMemeSuggestion)}
          onCloseDrawer={() => setSelectedMemeSuggestion(undefined)}
        >
          {selectedMemeSuggestion && (
            <MemeSuggestionDetail
              memeSuggestionDetail={selectedMemeSuggestion}
              onCloseDrawer={() => setSelectedMemeSuggestion(undefined)}
            />
          )}
        </CustomDrawer>
      </div>
    </>
  );
};

export default MemeSuggestionsListPage;
