import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Timer,
  DetailCard,
  Image,
  Button,
  CustomStepper,
  IconDisplay,
} from "components";
import _ from "lodash";
import { DELIVERY_STATUS_STEPS, ORDER_STATUS_STEPS } from "constants";
import {
  displayCurrencyAmount,
  displayDateInFormat,
  displayDriveImage,
  calculateOrderCancellationDeadlineInSeconds,
  isDateExpired,
} from "utils";
import "./index.css";
import "./mobile.css";

const OrderCard = ({
  order,
  onCancelOrder,
  onRaiseTicket,
  onViewDetail,
  onSubmitOrderFeedback,
  onViewTicketDetail,
  onDownloadInvoice,
}) => {
  const {
    receipt,
    amount,
    products,
    estimatedDeliveryDate,
    lastReturnDate,
    refundDate,
    paymentTime,
    cancellationDate,
    orderStatus,
    deliveryStatus,
    orderStatusIndex,
    deliveryStatusIndex,
    activeOrderStatus,
    activeDeliveryStatus,
    ticketDetail,
  } = order || {};

  const title = _.reduce(
    products,
    (finalTitle, { productItem }) => `${finalTitle} ${productItem?.title},`,
    ""
  ).slice(0, -1);

  const remainingTime =
    calculateOrderCancellationDeadlineInSeconds(paymentTime);

  const image = _.first(_.first(products)?.images);

  const displayHeaderLeft = () => {
    let content = ``;
    const dateFormat = "Do MMMM YYYY";
    if (activeOrderStatus === "PAID") {
      content = (
        <p>
          Est. Delivery Date{": "}
          {displayDateInFormat(estimatedDeliveryDate, dateFormat)}
        </p>
      );
    } else if (activeOrderStatus === "CANCELLED") {
      content = (
        <p>
          Cancelled On{": "}
          {displayDateInFormat(cancellationDate, dateFormat)}
        </p>
      );
    } else if (
      activeOrderStatus === "REFUND_REQUEST" ||
      activeOrderStatus === "REFUNDED"
    ) {
      content = (
        <p>
          Refund Requested On{": "}
          {displayDateInFormat(refundDate, dateFormat)}
        </p>
      );
    } else if (activeDeliveryStatus === "DELIVERED") {
      content = (
        <p>
          Return Window Closes On{": "}
          {displayDateInFormat(lastReturnDate, dateFormat)}
        </p>
      );
    }
    return content;
  };

  const generateStepperSteps = (statusData, stepData) => {
    const { icon, label } =
      _.find(
        stepData,
        ({ description }) => description === statusData.description
      );
    return {
      icon: (
        <IconDisplay
          type={icon}
          className="myOrder__card-content-timeline-icon"
        />
      ),
      label,
    };
  };

  return (
    <DetailCard
      className="myOrder__card"
      headerClass="myOrder__card-header"
      headerContent={
        <>
          {displayHeaderLeft()}
          {activeOrderStatus === "PAID" && (
            <p className="div__orderCard-cancelDeadline">
              {remainingTime > 0 && `Time Left To Cancel Order: `}
              <Timer
                timeout={remainingTime}
                timeoutMessage="Cancellation Window Closed"
              />
            </p>
          )}
        </>
      }
      contentClass="myOrder__card-content"
      cardContent={
        <div className="myOrder__card-content-container">
          <div className="myOrder__card-content-itemDetail">
            <Image
              imageURL={displayDriveImage(image)}
              className="myOrder__card-content-image"
            />
            <div className="myOrder__card-content-details">
              <p className="myOrder__card-content-details-orderId">
                #{receipt?.toUpperCase()}
              </p>
              <p className="myOrder__card-content-details-title">{title}</p>
              <p className="myOrder__card-content-details-amount">
                {displayCurrencyAmount(amount)}
                <Button
                  className="myOrder__card-content-details-invoice"
                  onClick={onDownloadInvoice}
                  startIcon={<DownloadIcon />}
                >
                  Invoice
                </Button>
              </p>
              <p className="myOrder__card-content-details-quantity">
                Total Items: {products?.length}
              </p>
            </div>
          </div>
          <div className="myOrder__card-content-timeline">
            <CustomStepper
              activeStep={orderStatusIndex}
              steps={_.map(
                _.uniqBy(
                  [...orderStatus, ...ORDER_STATUS_STEPS],
                  "description"
                ),
                (statusData) =>
                  generateStepperSteps(statusData, ORDER_STATUS_STEPS)
              )}
              labelClass="myOrder__card-content-timeline-label"
            />
            <CustomStepper
              activeStep={deliveryStatusIndex}
              steps={_.map(
                _.uniqBy(
                  [...deliveryStatus, ...DELIVERY_STATUS_STEPS],
                  "description"
                ),
                (statusData) =>
                  generateStepperSteps(statusData, DELIVERY_STATUS_STEPS)
              )}
              labelClass="myOrder__card-content-timeline-label"
            />
          </div>
        </div>
      }
      actionButtons={
        <>
          {activeOrderStatus === "PAID" && remainingTime > 0 && (
            <>
              <div onClick={onCancelOrder}>Cancel Order</div>
              <div className="verticalDivider" />
            </>
          )}
          {activeDeliveryStatus === "DELIVERED" &&
            !isDateExpired(lastReturnDate) && (
              <>
                <div
                  onClick={
                    _.isEmpty(ticketDetail) ? onRaiseTicket : onViewTicketDetail
                  }
                >
                  {_.isEmpty(ticketDetail) ? `Raise` : `View`} Ticket
                </div>
                <div className="verticalDivider" />
              </>
            )}
          {activeDeliveryStatus === "DELIVERED" &&
            isDateExpired(lastReturnDate) && (
              <>
                <div onClick={onSubmitOrderFeedback}>Leave Order Feedback</div>
                <div className="verticalDivider" />
              </>
            )}
          {activeOrderStatus === "CANCELLED" && (
            <>
              <div onClick={onRaiseTicket}>Buy Again</div>
              <div className="verticalDivider" />
            </>
          )}
          <div onClick={onViewDetail}>Order Info</div>
        </>
      }
    />
  );
};

export default OrderCard;
