import { useState } from "react";

const useConfirmation = ({ onCancel, onConfirm }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const showPopup = () => setIsPopupOpen(true);

  const handleClose = () => {
    setIsPopupOpen(false);
    onCancel && onCancel();
  };

  const handleConfirm = () => {
    setIsPopupOpen(false);
    onConfirm();
  };

  return {
    isPopupOpen,
    showPopup,
    handleClose,
    handleConfirm,
  };
};

export default useConfirmation;
