import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ShortImagePreview, DropdownInput, IconDisplay } from "components";
import { displayCurrencyAmount /*displayDateInFormat*/, displayDriveImage } from "utils";
import "./index.css";
import "./mobile.css";

const CartItem = ({
  cartItem,
  onQuantityChange,
  onAddToWishlist,
  onDeleteItem,
}) => {
  const { productId, inventoryId, quantity /*estimatedDeliveryDate*/ } =
    cartItem;
  const { title, contentLanguage, inWishlist } = productId;
  const { _id, color, discount, sizeSymbol, gender, images } = inventoryId;
  const [itemQuantity, setItemQuantity] = useState(quantity);

  const discountedPrice = Math.floor(inventoryId?.price * (1 - discount / 100));

  return (
    <div className="div__cart-item">
      <div className="div__cart-item-header">
        {/*
        #TODO: Display Estimated delivery date upon delivery partner integration 
        <p>Estimated Delivery Date</p>
        <p>{displayDateInFormat(estimatedDeliveryDate, "Do MMMM YYYY")}</p> */}
      </div>
      <div className="div__cart-item-content">
        <div className="div__cart-item-detail">
          <p className="div__cart-item-detail-title">
            {title} ({contentLanguage})
          </p>
          {discount > 0 && (
            <p className="div__cart-item-detail-discountedPrice">
              {displayCurrencyAmount(discountedPrice)}
              <span className="div__cart-item-detail-actualPrice">
                {displayCurrencyAmount(inventoryId?.price)}
              </span>
              <span className="div__cart-item-detail-discount">{`${discount} %`}</span>
            </p>
          )}
          {!discount && (
            <p className="div__cart-item-detail-discountedPrice">
              {displayCurrencyAmount(inventoryId?.price)}
            </p>
          )}
          <div className="div__cart-item-inventoryDetail">
            <span className="div__cart-item-inventoryDetail-size">Size: {sizeSymbol}</span>
            <span className="div__cart-item-inventoryDetail-gender">Gender: {gender}</span>
            <div className="div__cart-item-inventoryDetail-color">
              <span className="div__cart-item-inventoryDetai-color-header">Color:</span>
              <div
                className="div__cart-item-inventoryDetail-color-circle"
                style={{ background: color }}
              />
              <span className="div__cart-item-inventoryDetail-color-text">{color}</span>
            </div>
          </div>
          <div className="div__cart-item-inventoryQuantity">
            <p className="div__cart-item-detail-itemQuantity">Qty: </p>
            <DropdownInput
              className="div__cart-item-detail-itemQuantity--input"
              optionsList={Array.from({ length: 5 }, (_, i) => i + 1).map(
                (option) => {
                  return { label: option, value: option };
                }
              )}
              selectedOption={itemQuantity}
              onSelect={({ target }) => {
                onQuantityChange(inventoryId?._id, target.value);
                setItemQuantity(target.value);
              }}
            />
          </div>
        </div>
        <Link
          to={`/product/${productId?._id}`}
          state={{
            inventoryId: _id,
          }}
        >
          <ShortImagePreview imageURL={displayDriveImage(images[0] || "")} />
        </Link>
      </div>
      <div className="horizontalDivider" />
      <div className="div__cart-item-actions">
        <div onClick={onDeleteItem}>Remove</div>
        <div className="verticalDivider" />
        <div onClick={onAddToWishlist}>
          <IconDisplay
            type={inWishlist ? "favorite" : "favoriteBorder"}
            className={inWishlist ? "iconWishlistSelected" : "iconWishlist"}
          />
          <span>{inWishlist ? "Remove from Wishlist" : "Add To Wishlist"}</span>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
