import { ErrorPage } from "components";
import { COMMON_ROUTES } from "./helpers";
import {
  LandingPage,
  AboutUs,
  LoginPage,
  RegisterPage,
  ProfilePage,
  OTPAuthPage,
  ProductDetailPage,
  SearchProductsPage,
  OrderSuccessPage,
} from "../pages";
import { SearchContextProvider } from "contexts";

const {
  HOME,
  LOGIN,
  REGISTER,
  EDIT_PROFILE,
  VIEW_PROFILE,
  ABOUT_US,
  PAGE_NOT_FOUND,
  UNAUTHORIZED,
  FORGOT_PASSWORD,
  SEARCH_PRODUCTS,
  PRODUCT_DETAIL,
  ORDER_SUCCESS,
} = COMMON_ROUTES;

const COMMON_ROUTES_LIST = [
  {
    path: PAGE_NOT_FOUND,
    Component: () => ErrorPage({ statusCode: 404 }),
    pageTitle: "Page Not Found",
  },
  {
    path: UNAUTHORIZED,
    Component: () => ErrorPage({ statusCode: 403 }),
    pageTitle: "Unauthorized",
  },
  {
    path: HOME,
    Component: LandingPage,
    pageTitle: "Landing Page",
  },
  { path: LOGIN, Component: LoginPage, pageTitle: "Login" },
  { path: REGISTER, Component: RegisterPage, pageTitle: "Register" },
  { path: EDIT_PROFILE, Component: RegisterPage, pageTitle: "Edit Profile" },
  { path: VIEW_PROFILE, Component: ProfilePage, pageTitle: "View Profile" },
  { path: ABOUT_US, Component: AboutUs, pageTitle: "About Us" },
  {
    path: FORGOT_PASSWORD,
    Component: OTPAuthPage,
    pageTitle: "Forgot Password",
  },
  {
    path: SEARCH_PRODUCTS,
    Component: SearchProductsPage,
    ContextProvider: SearchContextProvider,
    pageTitle: "Search Products",
  },
  {
    path: PRODUCT_DETAIL,
    Component: ProductDetailPage,
    pageTitle: "Product Detail",
  },
  {
    path: ORDER_SUCCESS,
    Component: OrderSuccessPage,
    pageTitle: "Order Success",
  },
];

export default COMMON_ROUTES_LIST;
