import React, { useState } from "react";
import { TextInputField, ActionIcon } from "components";
import "./index.css";

const ChatFooter = ({ onTyping, onSendMessage }) => {
  const [message, setMessage] = useState("");

  const handleMessageChange = ({ target: { value } }) => {
    onTyping();
    setMessage(value);
  };

  const handleSendMessage = () =>
    setMessage((msg) => {
      onSendMessage(msg);
      return "";
    });

  return (
    <div className="ChatFooter__Container">
      <TextInputField
        inputValue={message}
        handleChange={handleMessageChange}
        handleEnterPress={handleSendMessage}
        variant="outlined"
        placeholder="Type your Message here"
      />

      <ActionIcon
        onClick={handleSendMessage}
        type="send"
        className="icon__SendButton"
      />
    </div>
  );
};

export default ChatFooter;
