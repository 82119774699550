import React, { useState } from "react";
import { Image } from "components";
import { Tabs, Tab } from "@material-ui/core";
import Logo from "assets/Meme-T-Logo.png";
import PAYMENT_METHODS from "../PaymentMethods";
import { displayDateInFormat } from "utils";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const MainLayout = ({
  savedPayments,
  customerName,
  paymentAmount,
  onPayment,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (_ev, newValue) => setSelectedTab(newValue);

  return (
    <div className="checkout__mainLayout__Container">
      <div className="checkout__mainLayout_left">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          orientation="vertical"
        >
          {PAYMENT_METHODS.map(({ label }) => (
            <Tab key={label} label={label} />
          ))}
        </Tabs>
      </div>

      <div className="checkout__mainLayout_right">
        <div className="checkout__header">
          <Image imageURL={Logo} className="checkout__header__logo" />
          <div className="checkout__header__text"> Meme-T Payment Gateway </div>
        </div>

        <div className="checkout_Order-Details">
          <p className="checkout__Order-Id">Customer Name: {customerName}</p>

          <p className="checkout_Order-Date">
            {displayDateInFormat(new Date(), "Do MMM YYYY")}
          </p>
        </div>

        <div className="checkout__Container_right-content">
          {PAYMENT_METHODS[selectedTab]?.component({
            savedPayments,
            paymentAmount,
            onPayment,
          })}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
