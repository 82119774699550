import React, { useState, useContext, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import _ from "lodash";
import {
  Rating,
  SizeSelector,
  // Input,
  ImagePreview,
  ShortImagePreview,
  ColorSelector,
  GenderSelector,
  Button,
  DropdownInput,
  IconDisplay,
  Image,
  CustomDrawer,
  Snackbar,
} from "components";
import { displayCurrencyAmount, displayDriveImage } from "utils";
import { UserContext } from "contexts";
import { useSnackbar, useAuth } from "hooks";
import {
  MachineWashIcon,
  IronBlackIcon,
  PersonIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  TshirtFitIcon,
} from "../icons";
import SizeChart from "./SizeChart";
import SizeChartSelectedItem from "./SelectedItem";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const ProductDetails = ({
  isPreview,
  productDetail,
  inventoryIdSelected,
  loading,
  setLoading,
}) => {
  const navigate = useNavigate();
  const { checkUserLoggedIn } = useAuth();
  const { modifyCurrentUserWishlist, modifyCurrentUserCart } =
    useContext(UserContext);
  const snackbar = useSnackbar();
  const { productData, inventoryData, availableSizeVariations, sizeOptions } =
    productDetail;
  const {
    _id,
    title,
    description,
    punDescription,
    rating,
    ratingsCount,
    size,
    fabricType,
    fabricDescription,
    washType,
    inWishlist,
    designedBy,
  } = productData || {};

  const cartSnackbar = (icon, msg) => (
    <div className="div__snackbar-cart">
      <p>{msg}</p>
      <IconDisplay type={icon} className="iconCart" />
    </div>
  );

  const defaultInventory =
    inventoryData.find((inv) => inv._id === inventoryIdSelected) ||
    _.first(inventoryData);

  // const [zipCode, setZipCode] = useState("");
  const [selectedInventory, setSelectedInventory] = useState(defaultInventory);
  const [mainImage, setMainImage] = useState(defaultInventory?.images[0] || "");
  const [productInWishlist, setProductInWishlist] = useState(inWishlist);
  const [sizeChartDrawer, setSizeChartDrawer] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(
    selectedInventory?.userQuantity || 1
  );

  const handleProductWishlist = async () => {
    checkUserLoggedIn();
    setProductInWishlist(!productInWishlist);
    const isSuccess = await modifyCurrentUserWishlist(_id, inWishlist);
    if (!isSuccess) {
      setProductInWishlist(!productInWishlist);
    }
    snackbar.showMessage({
      content: cartSnackbar(
        "favorite",
        `${title} ${
          productInWishlist ? "removed from" : "added to"
        } your wishlist`
      ),
    });
  };

  const displayImagesList = selectedInventory?.images?.map((imageURL) => (
    <ShortImagePreview
      key={imageURL}
      imageURL={displayDriveImage(imageURL)}
      isMainImage={imageURL === mainImage}
      setAsMainImage={() => setMainImage(imageURL)}
    />
  ));

  const displayFabricTile = (
    iconComponent,
    titleLabel,
    descriptionLabel,
    extraDescription = ""
  ) =>
    descriptionLabel && (
      <div className="div__fabricDescription">
        <div>{iconComponent}</div>
        <div>
          <p className="fabricTitle">{titleLabel}</p>
          <p className="fabricDescription">{descriptionLabel}</p>
          {extraDescription && (
            <p className="fabricDescription">{extraDescription}</p>
          )}
        </div>
      </div>
    );

  // Method for size selection and viewing available colors and genders
  const handleSizeSelection = (sizeSelected = "") => {
    const inventoryFound = inventoryData.filter(
      (item) => item.sizeSymbol === sizeSelected
    )[0];
    setSelectedInventory(inventoryFound);
    setMainImage(inventoryFound?.images[0] || "");
    setCartQuantity(inventoryFound?.userQuantity || 1);
  };

  // Selecting particular product inventory based on color and gender selection
  const handleInventorySelection = (colorSelected, genderSelected) => {
    const inventoryFound = inventoryData.filter(
      (item) =>
        item.sizeSymbol === selectedInventory.sizeSymbol &&
        item.color === colorSelected &&
        item.gender ===
          (genderSelected ||
            availableSizeVariations[selectedInventory.sizeSymbol][
              colorSelected
            ][0])
    )[0];
    if (inventoryFound) {
      setSelectedInventory(inventoryFound);
      setMainImage(inventoryFound?.images[0] || "");
    }
    setCartQuantity(inventoryFound?.userQuantity || 1);
  };

  const handleAddToCart = async (buyNowFlag) => {
    checkUserLoggedIn();
    setLoading(true);
    const { success, msg } = await modifyCurrentUserCart({
      productId: productData?._id,
      inventoryId: selectedInventory?._id,
      quantity: cartQuantity,
      method: buyNowFlag ? "BUY_NOW" : "ADD",
      price: selectedInventory?.price,
      discount: selectedInventory?.discount,
      vendorId: productData?.designedBy?._id,
    });
    setLoading(false);
    if (buyNowFlag) {
      navigate(`/cart`);
    } else {
      setSelectedInventory((prevItem) => ({
        ...prevItem,
        inCart: success,
      }));
    }
    snackbar.showMessage({
      content: cartSnackbar("shoppingCart", msg),
    });
  };

  // Remove inventory from cart
  const removeFromCart = async () => {
    checkUserLoggedIn();
    setLoading(true);
    const { msg, success } = await modifyCurrentUserCart({
      inventoryId: selectedInventory?._id,
      method: "REMOVE",
    });
    setLoading(false);
    setSelectedInventory((prevItem) => {
      setCartQuantity(1);
      return {
        ...prevItem,
        inCart: !success,
      };
    });
    snackbar.showMessage({
      content: cartSnackbar("shoppingCart", msg),
    });
  };

  const modifyCartItemQuantity = async (qty) => {
    if (selectedInventory?.inCart) {
      checkUserLoggedIn();
      // If product already in cart, modify in backend
      setLoading(true);
      const { msg, success } = await modifyCurrentUserCart({
        inventoryId: selectedInventory?._id,
        quantity: qty,
        method: "UPDATE",
      });
      setLoading(false);
      snackbar.showMessage({
        content: cartSnackbar("shoppingCart", msg),
      });
      setCartQuantity((prevVal) => (success ? qty : prevVal));
    }
    // else set quantity synchronously before aadding to cart
    setCartQuantity(qty);
  };

  const { price, discountedPrice, inCart, discount } = selectedInventory;
  const isInventoryAvailable = inventoryData?.length > 0;
  const hasDiscount = discount > 0;

  const availableGendersList = useMemo(
    () =>
      availableSizeVariations[selectedInventory?.sizeSymbol][
        selectedInventory?.color
      ],
    [availableSizeVariations, selectedInventory]
  );
  const isUnisexAvailable = availableGendersList?.some(
    (gender) => gender === "Unisex"
  );

  const displayActionButtons = (includeWishlist) => (
    <>
      {includeWishlist && (
        <Button
          size="large"
          className={`btnWishlist ${
            productInWishlist ? "btnAddedToWishlist" : ""
          }`}
          onClick={handleProductWishlist}
          endIcon={
            <IconDisplay
              className="iconAddToWishlist"
              type={productInWishlist ? "favorite" : "favoriteBorder"}
            />
          }
        >
          {productInWishlist ? "Added to Wishlist" : "Wishlist"}
        </Button>
      )}
      <Button
        size="large"
        className={`btnAddToCart ${inCart ? "btnAddedToCart" : ""}`}
        disabled={!isInventoryAvailable}
        onClick={() => (inCart ? removeFromCart() : handleAddToCart())}
        startIcon={<ShoppingCartIcon className="iconAddToCart" />}
      >
        {inCart ? "Remove from Cart" : "Add To Cart"}
      </Button>
      <Button
        size="large"
        className="btnBuyNow"
        disabled={!isInventoryAvailable}
        onClick={() => handleAddToCart(true)}
        startIcon={<ShoppingBagIcon className="iconBuyNow" />}
      >
        Buy Now
      </Button>
    </>
  );

  return (
    <>
      <Snackbar {...snackbar} />
      <div className="div__productDetail-images">
        <div className="div__productDetail-imagesSection">
          <div className="div__productDetail-imagesList">
            {displayImagesList}
          </div>
          <div className="div__productDetail-imagePreview">
            <ImagePreview
              imageSource={displayDriveImage(mainImage)}
              className="imgDetailPreview"
            />
          </div>
        </div>
        <div className="div__productDetail-fabricSection">
          <div>
            {displayFabricTile(
              <Image
                imageURL={TshirtFitIcon}
                alt="sizeFit"
                className="fabricSvg"
              />,
              "Size & Fit",
              size
            )}
            {displayFabricTile(
              <IronBlackIcon className="fabricSvg" />,
              "Material & Fabric Description",
              fabricType,
              fabricDescription
            )}
          </div>
          <div>
            {displayFabricTile(
              <MachineWashIcon className="fabricSvg" />,
              "Wash",
              washType
            )}
            {displayFabricTile(
              <PersonIcon className="fabricSvg" />,
              "Design By",
              designedBy && (
                <Link
                  className="visitProfile"
                  to={`/profile/${designedBy?._id}`}
                >
                  <div className="div__productDetail-designedBy">
                    <Avatar
                      alt={designedBy?.name}
                      src={designedBy?.profilePic}
                    />
                    <p className="lblProductDesignedBy">{designedBy?.name}</p>
                  </div>
                </Link>
              )
            )}
          </div>
        </div>
      </div>
      <div className="div__productDetail-content">
        <p className="productTitle">{title}</p>
        <p className="productDetail">{description}</p>
        <p className="productPunDescription">
          Pun Description: {punDescription}
        </p>
        <div className="div__productDetail-content-cartOptions">
          {!isPreview && (
            <div className="div__productDetail-content-rating">
              <Rating value={rating} readOnly />
              <p className="ratingsCount">({ratingsCount})</p>
            </div>
          )}
          {!isPreview && (
            <div className="div__productDetail-content-cartQuantity">
              <p>Quantity </p>
              <DropdownInput
                className="dropdownCartQuantity"
                disabled={!isInventoryAvailable}
                optionsList={Array.from({ length: 5 }, (_ev, i) => i + 1).map(
                  (option) => {
                    return { label: option, value: option };
                  }
                )}
                selectedOption={cartQuantity}
                onSelect={({ target }) => modifyCartItemQuantity(target.value)}
              />
            </div>
          )}
          <div className="div__productDetail-content-sizeSelect">
            {isInventoryAvailable && (
              <>
                <SizeSelector
                  labelText="Select Size"
                  sizeOptions={sizeOptions}
                  sizeSelected={selectedInventory?.sizeSymbol}
                  onSelectOption={(sizeVal) =>
                    !isPreview && handleSizeSelection(sizeVal)
                  }
                />
                {!isPreview && (
                  <p
                    className="lnkSizeChart"
                    onClick={() => setSizeChartDrawer(true)}
                  >{`Size Chart >`}</p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="div__productDetail-inventoryCart">
          <div
            className="div__productDetail-priceColorGender"
            style={{
              justifyContent: isUnisexAvailable
                ? "space-between"
                : "space-around",
            }}
          >
            <div className="div__productDetail-priceDetails">
              <p className="discountedPrice">
                {hasDiscount
                  ? displayCurrencyAmount(discountedPrice)
                  : displayCurrencyAmount(price)}
              </p>
              {hasDiscount && (
                <p className="actualPrice">{displayCurrencyAmount(price)}</p>
              )}
              {hasDiscount && (
                <p className="discount">{`(${discount}% off)`}</p>
              )}
            </div>
            {isInventoryAvailable ? (
              <div
                className="div_productDetail-selectColor"
                style={{
                  flex: isUnisexAvailable ? 0.5 : 0.35,
                }}
              >
                <ColorSelector
                  labelText="Select Color"
                  colorOptions={
                    availableSizeVariations[selectedInventory?.sizeSymbol]
                      .colorsList
                  }
                  colorSelected={selectedInventory?.color}
                  onSelectOption={(colorVal) =>
                    !isPreview && handleInventorySelection(colorVal)
                  }
                />
              </div>
            ) : (
              <p className="lblOutOfStock">Out Of Stock</p>
            )}
            <div className="div__productDetail-selectGender">
              {!isUnisexAvailable && (
                <GenderSelector
                  availableGenders={availableGendersList}
                  selectedGender={selectedInventory?.gender}
                  onSelect={(val) =>
                    !isPreview &&
                    handleInventorySelection(selectedInventory?.color, val)
                  }
                />
              )}
            </div>
          </div>
          <div className="div__productDetail-cartActions">
            {!isPreview && displayActionButtons(true)}
          </div>
        </div>
      </div>
      <div className="div__productDetail-delivery">
        {/* <p>
          Delivery Options
          <IconDisplay type="localShipping" className="localShippingIcon" />
        </p>
        <Input
          className="txtDeliveryCode"
          type="number"
          placeholder="PIN"
          value={zipCode}
          onChange={({ target }) => setZipCode(target.value)}
        />
        <p className="deliveryInstruction">Check Availability</p>
        <p className="deliveryInstruction">{`Get it by ${new Date().toDateString()}`}</p> */}
      </div>
      <CustomDrawer
        direction="bottom"
        className="sizeChartDrawer"
        isOpen={sizeChartDrawer}
        onCloseDrawer={() => setSizeChartDrawer(false)}
      >
        <SizeChart
          defaultSize={selectedInventory?.sizeSymbol}
          sizesAvailable={sizeOptions}
          onSelectSize={(sizeVal) => handleSizeSelection(sizeVal)}
          loading={loading}
          imageSelected={mainImage}
          selectedItemContent={
            <SizeChartSelectedItem
              productData={productData}
              selectedInventory={{ ...selectedInventory, hasDiscount }}
              mainImage={mainImage}
            />
          }
          actionButtons={displayActionButtons()}
        />
      </CustomDrawer>
    </>
  );
};

export default ProductDetails;
