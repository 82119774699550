import React, { useState, useContext } from "react";
import _ from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  DetailCard,
  TextAreaInputField,
  Button,
  RowDetail,
  Image,
  Loading,
  Snackbar,
} from "components";
import { UserContext } from "contexts";
import { TicketServices } from "services";
import { useSnackbar } from "hooks";
import { displayDateInFormat, displayDriveImage } from "utils";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const ReplyTicket = ({ ticketDetail, onCloseDrawer }) => {
  const { currentUser } = useContext(UserContext);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const {
    _id,
    consumerName,
    createdAt,
    attachments,
    activeStatus,
    description,
    category,
    vendorReply,
  } = ticketDetail;

  const rowDetails = [
    { label: "Ticket ID", detail: _id },
    { label: "Consumer Name", detail: consumerName },
    { label: "Status", detail: activeStatus },
    { label: "Ticket Description", detail: description },
  ];

  const initialValues = {
    ticketReply: vendorReply || "",
  };

  return (
    <>
      <Snackbar {...snackbar} />
      {loading && <Loading />}
      <div className="replyTicket-container">
        <div className="replyTicket-container__heading">{category} Ticket</div>
        <div className="replyTicket-container__view">
          <div className="replyTicket-container__leftGallery">
            <DetailCard
              className="replyTicket-container__leftGallery-ticketImages"
              headerContent="Ticket Images"
              headerClass="replyTicket-container__leftGallery-ticketImages-header"
              cardContent={
                <>
                  {!_.isEmpty(attachments) ? (
                    attachments.map((img) => (
                      <Image
                        className="replyTicket-container__leftGallery-ticketImage"
                        imageURL={displayDriveImage(img)}
                      />
                    ))
                  ) : (
                    <p className="replyTicket-container__leftGallery-noAttachments">
                      No attachments available
                    </p>
                  )}
                </>
              }
              contentClass="replyTicket-container__leftGallery-ticketImages-content"
            />
          </div>
          <div className="replyTicket-container__rightGallery">
            <DetailCard
              className="replyTicket-container__rightGallery-ticketDetails-header"
              headerContent={
                <>
                  <p>Ticket Description</p>
                  <p>
                    Created At: {displayDateInFormat(createdAt, "Do MMM YYYY")}
                  </p>
                </>
              }
              headerClass="replyTicket-container__rightGallery-ticketDetails-header"
              cardContent={
                <>
                  {rowDetails.map(({ label, detail }) => (
                    <RowDetail label={label} description={detail} />
                  ))}
                </>
              }
              contentClass="replyTicket-container__rightGallery-ticketDetails-content"
            />
            {activeStatus !== "Resolved" &&
              (vendorReply ? (
                <div className="replyTicket-container__rightGallery-ticketDetails-reply">
                  <p className="replyTicket-container__rightGallery-ticketDetails-replyHeader">
                    Your Reply:
                  </p>
                  <p className="replyTicket-container__rightGallery-ticketDetails-replyContent">
                    {vendorReply}
                  </p>
                </div>
              ) : (
                <Formik
                  initialValues={initialValues}
                  validationSchema={Yup.object().shape({
                    ticketReply: Yup.string()
                      .required("Ticket Reply is mandatory")
                      .max(
                        250,
                        "Ticket Reply should not exceed 250 characters"
                      ),
                  })}
                  onSubmit={async ({ ticketReply }) => {
                    try {
                      setLoading(true);
                      const payload = {
                        _id,
                        vendorId: currentUser?._id,
                        vendorReply: ticketReply,
                        ticketStatus: {
                          description: "Resolved",
                          timestamp: new Date(),
                        },
                      };
                      const { msg } = await TicketServices.createUpdateTicket(
                        payload
                      );
                      setLoading(false);
                      snackbar.showMessage({
                        content: msg,
                      });
                    } catch (err) {
                      setLoading(false);
                      snackbar.showMessage({
                        content: err,
                      });
                    }
                  }}
                >
                  {({
                    values: { ticketReply },
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    touched,
                    errors,
                  }) => (
                    <>
                      <TextAreaInputField
                        name="ticketReply"
                        className="replyTicket-container__comment"
                        labelText="Reply To Consumer"
                        inputValue={ticketReply}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errorText={touched.ticketReply && errors.ticketReply}
                      />
                      <div className="replyTicket-container__actionButtons">
                        <Button
                          className="replyTicket-container__actionButtons-resolve"
                          onClick={handleSubmit}
                        >
                          Resolve Ticket
                        </Button>
                        <Button
                          className="replyTicket-container__actionButtons-cancel"
                          onClick={onCloseDrawer}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  )}
                </Formik>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReplyTicket;
