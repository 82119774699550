import React, { useState, useContext, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  DropdownInput,
  TextAreaInputField,
  Snackbar,
  Button,
  Loading,
  DragAndDropUpload,
} from "components";
import { modifyList, generateDropdownOptions } from "utils";
import { TICKET_CATEGORY_OPTIONS, FILE_TYPES } from "constants";
import { TicketServices } from "services";
import { UserContext } from "contexts";
import { useSnackbar } from "hooks";
import OrderItems from "pages/Orders/MyOrders/MyOrdersList/OrderDetail/OrderItems";
import "./index.css";

const { IMAGE_JPEG, IMAGE_JPG, IMAGE_PNG } = FILE_TYPES;

const AddTicketForm = ({
  ticketDetail,
  ticketCategory,
  orderDetail,
  onCloseDrawer,
}) => {
  const { currentUser } = useContext(UserContext);
  const snackbar = useSnackbar();
  const [attachments, setAttachments] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const { _id, category, description } = ticketDetail || {};
  const { _id: orderId, products } = orderDetail || {};

  return (
    <Formik
      initialValues={{
        category: ticketCategory || category || "Select Category",
        description: description || "",
      }}
      validationSchema={Yup.object().shape({
        category: Yup.string()
          .required("Ticket Category is mandatory")
          .test(
            "description",
            "Ticket Description is Mandatory",
            (value) => !_.isEmpty(value) && value !== "Select Category"
          ),
        description: Yup.string()
          .required("Ticket description is mandatory")
          .max(1000, "Ticket Description should not exceed 250 characters"),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values, { setSubmitting }) => {
        const formData = new FormData();
        let payload = {
          ...values,
          customerId: currentUser?._id,
        };
        if (!_.isEmpty(orderId)) {
          payload = {
            ...payload,
            orderId,
          };
        }
        if (!_id) {
          formData.append(`statusList[0][description]`, "Created");
          formData.append(`statusList[0][timestamp]`, new Date());
        }
        if (!_.isEmpty(selectedItems)) {
          formData.append("orderItems", JSON.stringify(selectedItems));
        }
        _.forEach(attachments, (fileInput) =>
          formData.append("attachments", fileInput)
        );
        _.each(payload, (value, key) => formData.append(key, value));
        setSubmitting(true);
        const { msg, ticketData } = await TicketServices.createUpdateTicket(
          formData
        );
        setSubmitting(false);
        snackbar.showMessage({
          content: msg,
          timeout: 5000,
          handleClose: onCloseDrawer && onCloseDrawer(ticketData),
        });
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        isSubmitting,
        handleSubmit,
      }) => (
        <>
          <Snackbar {...snackbar} />
          {isSubmitting && <Loading />}
          <div className="addTicket__form">
            <p className="addTicket__form-header">RAISE TICKET</p>
            <div className="addTicket__form-container">
              {!_.isEmpty(products) && (
                <div className="addTicket__form--left">
                  <OrderItems
                    products={products}
                    isSelectMode
                    selectedItems={selectedItems}
                    onSelectItem={(itemId) =>
                      setSelectedItems((prevList) =>
                        modifyList(prevList, itemId)
                      )
                    }
                  />
                </div>
              )}
              <div className="addTicket__form--right">
                <div className="addTicket__form-selectCategory">
                  <span>Select Category</span>
                  <DropdownInput
                    name="category"
                    className="addTicket__form-selectCategory-dropdown"
                    optionsList={generateDropdownOptions(
                      TICKET_CATEGORY_OPTIONS
                    )}
                    selectedOption={values.category}
                    onSelect={handleChange}
                    onBlur={handleBlur}
                    errorText={touched.category && errors.category}
                  />
                </div>
                <div className="addTicket__form-attachImages">
                  <p className="addTicket__form-attachImages-label">
                    Add Images for Relevant Problem
                  </p>
                  <DragAndDropUpload
                    label={`Drag and Drop reference images for your ticket`}
                    maxFiles={5}
                    className="addTicket__form-attachImages-input"
                    acceptedTypes={[IMAGE_JPEG, IMAGE_JPG, IMAGE_PNG]}
                    onDropHandler={(acceptedFiles) => {
                      console.log(`Accepted Files`, acceptedFiles);
                      setAttachments(acceptedFiles);
                    }}
                  />
                </div>
                <TextAreaInputField
                  name="description"
                  className="addTicket__form-description"
                  minRows={7}
                  labelText="Describe the issue*"
                  inputValue={values.description}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorText={touched.description && errors.description}
                />
                <div className="addTicket__form-buttons">
                  <Button
                    id="btnInput"
                    className="addTicket__form-cancel"
                    onClick={() => onCloseDrawer()}
                  >
                    Cancel Ticket
                  </Button>
                  <Button
                    id="btnInput"
                    className="addTicket__form-submit"
                    onClick={() => handleSubmit()}
                  >
                    Raise Ticket
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default AddTicketForm;
