import React from "react";
import { useNavigate } from "react-router-dom";
import Zoom from "@mui/material/Zoom";
import { Image, Button } from "components";
import OrderSuccessImage from "assets/OrderSuccess.gif";
import "./index.css";

const OrderSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <Zoom in style={{ transitionDuration: "1000ms" }}>
      <div className="order__success">
        <Image
          imageURL={OrderSuccessImage}
          className="order__success-image"
          alt="loadingLogo"
        />
        <p className="order__success-message">
          Thank you for empowering vernaculars and giving us an opportunity to
          serve you
        </p>
        <div className="order__success-actions">
          <Button
            className="order__success-actions--viewOrder"
            onClick={() => navigate(`/myOrders`)}
          >
            View Order
          </Button>
          <Button
            id="btnInput"
            className="order__success-actions--continueShopping"
            onClick={() => navigate(`/products/search`)}
          >
            Continue Shopping
          </Button>
        </div>
      </div>
    </Zoom>
  );
};

export default OrderSuccessPage;
