import React, { useContext, useMemo, useState } from "react";
import _ from "lodash";
import { Formik } from "formik";
import * as yup from "yup";
import { Collapse } from "@material-ui/core";
import {
  Snackbar,
  Loading,
  TextInputField,
  Button,
  DropdownInput,
  Timer,
} from "components";
import { UserContext } from "contexts";
import { useSnackbar } from "hooks";
import { VendorServices } from "services";
import {
  encodeDecodeData,
  generateDropdownOptions,
  auth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "utils";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const WithdrawalForm = ({
  bankAccounts,
  transactionLimit,
  phoneNumber,
  onFormSubmit,
}) => {
  const snackbar = useSnackbar();
  const { currentUser } = useContext(UserContext);
  const [verificationStep, setVerificationStep] = useState(0);
  const [OTPObject, setOTPObject] = useState({
    isOTPSent: false,
    OTPConfirmation: {},
    timeoutOTP: 0,
  });
  const [firebaseToken, setFirebaseToken] = useState(false);

  const isFirebaseTokenGenerated = useMemo(
    () => !_.isEmpty(firebaseToken),
    [firebaseToken]
  );

  const handleSendOTP = async () => {
    try {
      auth.languageCode = "en";
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptchaContainer",
        {
          size: "invisible",
        },
        auth
      );
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window.recaptchaVerifier
      );
      setOTPObject({
        OTPConfirmation: confirmationResult,
        isOTPSent: true,
        timeoutOTP: 60,
      });
      return `An OTP has been sent at ${phoneNumber}`;
    } catch (err) {
      console.log(`Send OTP Error`, err);
      return `Error occurred during sending OTP to ${phoneNumber}`;
    }
  };

  const verifyOTPCode = async (otpCode, setSubmitting) => {
    try {
      setSubmitting(true);
      const verificationResponse = await OTPConfirmation.confirm(otpCode);
      setSubmitting(false);

      if (_.isEmpty(verificationResponse)) {
        snackbar.showMessage({
          content: `Invalid OTP`,
        });
        return;
      }
      setFirebaseToken(verificationResponse?._tokenResponse?.idToken);
      snackbar.showMessage({
        content: `OTP verified successfully`,
      });
      return true;
    } catch (err) {
      snackbar.showMessage({
        content: `Error ocurred during Firebase OTP verification`,
      });
      return false;
    }
  };

  const sendOTPToPhone = async (setSubmitting) => {
    setSubmitting(true);
    try {
      const msg = await handleSendOTP();
      setSubmitting(false);
      snackbar.showMessage({
        content: msg,
      });
      return true;
    } catch (err) {
      console.error(`Error`, err);
      return false;
    }
  };

  const handleResendOTP = async (setSubmitting) => {
    await window.recaptchaVerifier.clear();
    await window.recaptchaVerifier.recaptcha.reset();
    const msg = await handleSendOTP();
    setSubmitting(false);
    snackbar.showMessage({
      content: msg,
    });
  };

  const displayBankAccountNumber = ({ bankAccountLength, lastFourDigits }) =>
    `${Array((bankAccountLength || 16) - 4)
      .fill("*")
      .join("")}${lastFourDigits}`;

  const verifyWalletPIN = async (walletPIN, setSubmitting) => {
    setSubmitting(true);
    const encodedWalletPIN = encodeDecodeData(walletPIN, "encode");
    try {
      const { msg } = await VendorServices.verifyWalletPIN(encodedWalletPIN);
      setSubmitting(false);
      snackbar.showMessage({
        content: msg,
      });
      return true;
    } catch (err) {
      snackbar.showMessage({
        content: err,
      });
      return false;
    }
  };

  const makeWithdrawalTransaction = async (payload, setSubmitting) => {
    setSubmitting(true);
    try {
      const { msg } = await VendorServices.makeWithdrawalTransaction(payload);
      setSubmitting(false);
      onFormSubmit && onFormSubmit(payload);
      snackbar.showMessage({
        content: msg,
      });
    } catch (err) {
      snackbar.showMessage({
        content: err,
      });
    }
  };

  const { OTPConfirmation, isOTPSent, timeoutOTP } = OTPObject;
  const labelSent = isOTPSent ? "Verify OTP" : "Send OTP";
  const buttonLabel = verificationStep !== 0 ? labelSent : "Verify PIN";

  return (
    <Formik
      initialValues={{
        bankAccountNumber: displayBankAccountNumber(
          _.first(bankAccounts) || {}
        ),
        transactionAmount: _.min(transactionLimit, 100),
        transactionPIN: "",
        transactionDescription: "",
        otpCode: "",
      }}
      enableReinitialize
      validationSchema={yup.object({
        transactionPIN:
          verificationStep === 0
            ? yup
                .string()
                .min(4, "Should contain exactly 4 digits")
                .max(4, "Should contain exactly 4 digits")
            : yup.string(),
        bankAccountNumber:
          verificationStep === 1
            ? yup.string().required("Bank Account No. is mandatory")
            : yup.string(),
        transactionAmount:
          verificationStep === 1
            ? yup
                .number()
                .required("Withdrawal amount is mandatory")
                .lessThan(
                  transactionLimit,
                  `Can't withdraw more than wallet balance`
                )
                .moreThan(0, `Withdrawal amount should be positive`)
            : yup.number(),
        transactionDescription: yup.string().nullable(),
        otpCode:
          verificationStep === 2 && isOTPSent
            ? yup
                .string()
                .min(6, "Should be exactly 6 digits")
                .max(6, "Should be exactly 6 digits")
                .required("OTP is mandatory")
            : yup.string(),
      })}
      validateOnBlur
      validateOnChange
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        if (verificationStep === 0) {
          const isPINVerified = await verifyWalletPIN(
            values.transactionPIN,
            setSubmitting
          );
          isPINVerified && setVerificationStep(1);
        } else if (verificationStep === 1) {
          const OTPSentSuccess = await sendOTPToPhone(setSubmitting);
          OTPSentSuccess && setVerificationStep(2);
        } else {
          const isOTPVerified = await verifyOTPCode(
            values.otpCode,
            setSubmitting
          );
          if (isOTPVerified) {
            const selectedBankAccountId = _.find(
              bankAccounts,
              (bankAccount) =>
                displayBankAccountNumber(bankAccount) ===
                values.bankAccountNumber
            )?._id;
            const savedPayload = {
              bankAccountId: encodeDecodeData(selectedBankAccountId, "encode"),
              transactionAmount: values.transactionAmount * -1,
              transactionType: "DEBIT",
              transactionIntent: "Withdrawal",
              transactionDescription: values.transactionDescription,
              vendorId: currentUser?._id,
            };
            await makeWithdrawalTransaction(savedPayload, setSubmitting);
          }
        }
      }}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        setSubmitting,
        isSubmitting,
      }) => (
        <>
          <Snackbar {...snackbar} />
          {isSubmitting && <Loading />}
          <form onSubmit={handleSubmit} className="withdrawal__form-container">
            {verificationStep === 0 && (
              <div className="withdrawal__form-verifyPIN">
                <p className="withdrawal__form-header--title">Wallet PIN</p>
                <p className="withdrawal__form-verifyPIN-header">
                  NOTE: For ensuring secure transactions, please enter your
                  4-digit wallet PIN.
                </p>
                <TextInputField
                  name="transactionPIN"
                  inputType="number"
                  placeholder="Enter your 4-digit wallet PIN"
                  obscureText
                  inputValue={values.transactionPIN}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorText={
                    touched.transactionAmount && errors.transactionAmount
                  }
                />
              </div>
            )}
            <Collapse in={verificationStep === 1} timeout="auto" unmountOnExit>
              <div className="withdrawal__form">
                <p className="withdrawal__form-header--title">
                  Wallet Withdrawal
                </p>
                <DropdownInput
                  name="bankAccountNumber"
                  className="withdrawal__form-bankAccountNumber"
                  labelText="Bank Account Number"
                  optionsList={generateDropdownOptions(
                    _.map(bankAccounts || [], (bankAccountDetail) =>
                      displayBankAccountNumber(bankAccountDetail)
                    )
                  )}
                  selectedOption={values.bankAccountNumber}
                  onSelect={handleChange}
                />
                <TextInputField
                  name="transactionAmount"
                  inputType="number"
                  labelText="Transaction Amount"
                  inputValue={values.transactionAmount}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorText={
                    touched.transactionAmount && errors.transactionAmount
                  }
                />
              </div>
            </Collapse>
            {verificationStep === 2 && !isFirebaseTokenGenerated && (
              <div className="div__OTPAuth-formContainer-verifyPhone">
                <Collapse in={isOTPSent} timeout="auto" unmountOnExit>
                  <div className="div__OTPAuth-formContainer-OTPMessage">
                    <p className="lblOTPMessage">
                      For ensuring secure transactions, we have sent an OTP to
                      your registered mobile number. Please enter it below to
                      complete your transaction.
                    </p>
                  </div>
                  <TextInputField
                    name="otpCode"
                    inputType="number"
                    labelText="OTP Code"
                    placeholder="Enter 6 digit OTP Code"
                    inputValue={values.otpCode}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={touched.otpCode && errors.otpCode}
                  />
                </Collapse>
                {isOTPSent && (
                  <div className="div__resendOTP">
                    <p
                      className="lnkResendOTP"
                      onClick={() => handleResendOTP(setSubmitting)}
                    >
                      Resend OTP
                    </p>
                    <Timer timeout={timeoutOTP} className="lblOTPTimeout" />
                  </div>
                )}
              </div>
            )}
            <Button
              id="btnInput"
              className="withdrawal__form-submit"
              disableRipple
              onClick={handleSubmit}
            >
              {buttonLabel}
            </Button>
            <div id="recaptchaContainer" />
          </form>
        </>
      )}
    </Formik>
  );
};
export default WithdrawalForm;
