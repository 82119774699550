import React from "react";
import { Image } from "components";
import { displayDriveImage } from "utils";
import "./index.css";

const OrderPrompt = ({ orderName, receipt, orderImage }) => (
  <div className="OrderPrompt__Container">
    <div className="OrderPromptCard">
      <Image
        className="OrderItemImage"
        imageURL={displayDriveImage(orderImage)}
      />
      <div className="OrderPromptCard__text">
        <p className="OrderName">{orderName}</p>
        <p className="OrderID">#{receipt}</p>
      </div>
    </div>
  </div>
);

export default OrderPrompt;
