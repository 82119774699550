import React, { useState } from "react";
import _ from "lodash";
import {
  ActionIcon,
  PaginatedTable,
  TextField,
  DateTimePickerInput,
  DropdownInput,
  Snackbar,
  CustomDrawer,
} from "components";
import { getTableDisplayConfig } from "displayConfigs";
import { TICKET_CATEGORY_OPTIONS, TICKET_STATUS_OPTIONS } from "constants";
import { generateDropdownOptions } from "utils";
import { useSnackbar } from "hooks";
import ReplyTicket from "./ReplyTicket";
import "./index.css";
import "./mobile.css";
import "./tablet.css";

const TicketsListPage = () => {
  const snackbar = useSnackbar();
  const [selectedTicket, setSelectedTicket] = useState();
  const [searchFilters, setSearchFilters] = useState();

  const ticketConfig = getTableDisplayConfig("Ticket", {
    actions: (rowVal) => (
      <div className="div_actionIcons">
        <ActionIcon
          type="chat"
          iconClassName="listIcon"
          onClick={() => console.log(`Open Chat`, rowVal)}
        />
        <ActionIcon
          type="view"
          iconClassName="listIcon"
          onClick={() => setSelectedTicket(rowVal)}
        />
      </div>
    ),
  });

  const handleSearchFiltersChange = (key, value) => {
    setSearchFilters({
      ...searchFilters,
      [key]: value,
    });
  };

  const { header } = ticketConfig;
  const { searchTerm, fromDate, toDate, category, status } =
    searchFilters || {};
  const queryFilters = {
    ...searchFilters,
    category: !_.isEqual(category, "Ticket Category") && category,
    status: !_.isEqual(status, "Ticket Status") && status,
    statusIndex:
      !_.isUndefined(status) &&
      !_.isEqual(status, "Ticket Status") &&
      _.findIndex(TICKET_STATUS_OPTIONS, (item) => _.isEqual(item, status)),
  };

  return (
    <>
      <Snackbar {...snackbar} />
      <div className="admin__ticketsList">
        {header}
        <div className="admin__ticketsList__filters">
          <div className="div__searchInput">
            <TextField
              className="admin__ticketsList__searchInput"
              placeholder="Search by TicketId or consumer name"
              value={searchTerm}
              onChange={({ target: { value } }) =>
                handleSearchFiltersChange("searchTerm", value)
              }
            />
          </div>
          <div className="div__Filters">
            <DateTimePickerInput
              inputType="Date"
              className="admin__ticketsList__fromDate"
              placeholder="From"
              dateValue={fromDate || null}
              handleChange={({ _d }) =>
                handleSearchFiltersChange("fromDate", _d.toISOString())
              }
            />
            <DateTimePickerInput
              inputType="Date"
              className="admin__ticketsList__toDate"
              dateValue={toDate || null}
              placeholder="To"
              handleChange={({ _d }) =>
                handleSearchFiltersChange("toDate", _d.toISOString())
              }
            />
            <DropdownInput
              className="admin__ticketsList__status"
              placeholder="Ticket Status"
              optionsList={generateDropdownOptions(TICKET_STATUS_OPTIONS)}
              selectedOption={status || "Ticket Status"}
              onSelect={({ target: { value } }) =>
                handleSearchFiltersChange("status", value)
              }
            />
            <DropdownInput
              className="admin__ticketsList__category"
              optionsList={generateDropdownOptions(TICKET_CATEGORY_OPTIONS)}
              placeholder="Ticket Category"
              selectedOption={category || "Ticket Category"}
              onSelect={({ target: { value } }) =>
                handleSearchFiltersChange("category", value)
              }
            />
          </div>
        </div>
        <PaginatedTable config={ticketConfig} queryFilters={queryFilters} />
        <CustomDrawer
          direction="bottom"
          className="admin__ticketsList__ticketDrawer--view"
          isOpen={!_.isUndefined(selectedTicket)}
          onCloseDrawer={() => setSelectedTicket(undefined)}
        >
          {selectedTicket && (
            <ReplyTicket
              ticketDetail={selectedTicket}
              onCloseDrawer={() => setSelectedTicket(undefined)}
            />
          )}
        </CustomDrawer>
      </div>
    </>
  );
};

export default TicketsListPage;
