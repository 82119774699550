import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Loading, Snackbar } from "components";
import { OrderServices } from "services";
import { UserContext } from "contexts";
import { useSnackbar } from "hooks";
import { generateOrderDataFromCart, displayCurrencyAmount } from "utils";
import MainLayout from "./MainLayout";
import "./index.css";

const razorpay = new window.Razorpay({
  key: process.env.REACT_APP_RAZORPAY_KEY,
});

const CheckOut = () => {
  const navigate = useNavigate();
  const { currentUser, modifyCurrentUser } = useContext(UserContext);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [savedPayments, setSavedPayments] = useState([]);

  const {
    _id,
    name,
    email,
    phoneNumber: contact,
    cart,
    address,
    razorpayCustomerId,
  } = currentUser;

  const fetchSavedPaymentMethods = async () => {
    setLoading(true);
    try {
      const { tokens } = await OrderServices.getSavedPaymentTokensList(
        razorpayCustomerId
      );
      setSavedPayments(tokens);
    } catch (err) {
      snackbar.showMessage({
        content: err,
      });
    }
    setLoading(false);
  };

  const { products, totalPrice, totalDiscount } =
    generateOrderDataFromCart(cart);
  const paymentAmount = totalPrice - totalDiscount + 40;

  const onPaymentComplete = (_payResp) => {
    modifyCurrentUser({ ...currentUser, cart: [] });
    navigate("/order/success");
  };

  const onPaymentFailure = async (_resp, paymentData) => {
    snackbar.showMessage({
      content: `Payment failed as ${_resp?.error?.description}`,
    });
    await OrderServices.notifyPaymentFailure(paymentData);
  };

  const makeRazorpayPayment = async (paymentData) => {
    setLoading(true);
    try {
      const payload = {
        amount: paymentAmount,
        currency: "INR",
      };

      if (_.isEmpty(currentUser?.razorpayCustomerId)) {
        payload["customerData"] = {
          name,
          contact,
          email,
          fail_existing: "0",
          // gstin: "29XA09A436910PC",
        };
      }

      const {
        orderData: { receipt, razorpayOrderId, amount },
      } = await OrderServices.createUpdateOrder(payload);
      setLoading(false);

      const data = {
        amount: (amount * 100).toString(),
        currency: "INR",
        email,
        contact,
        description: `${name}'s Meme-T Order ${receipt}`,
        notes: {
          products: JSON.stringify(products),
          customerId: _id,
          receipt,
          shippingAddressId: address?._id,
          billingAddressId: address?._id,
        },
        customer_id: razorpayCustomerId,
        order_id: razorpayOrderId,
        ...paymentData,
      };

      razorpay.createPayment(data);
      razorpay.on("payment.success", onPaymentComplete); // will pass payment ID, order ID, and Razorpay signature to success handler.

      razorpay.on("payment.error", (_resp) =>
        onPaymentFailure(_resp, {
          ...paymentData,
          name,
          email,
          receipt,
          amount,
          failureMessage: _resp?.error?.description,
        })
      ); // will pass error object to error handler
    } catch (err) {
      setLoading(false);
      console.log(err);
      snackbar.showMessage({
        content: err,
      });
    }
  };

  useEffect(() => {
    fetchSavedPaymentMethods();
  }, [razorpayCustomerId]);

  return (
    <>
      {loading && <Loading />}
      <Snackbar {...snackbar} />
      <div className="checkOut">
        <MainLayout
          savedPayments={savedPayments}
          customerName={name}
          paymentAmount={`${displayCurrencyAmount(paymentAmount)}/-` || ""}
          onPayment={makeRazorpayPayment}
        />
      </div>
    </>
  );
};

export default CheckOut;
