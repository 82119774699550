import IconDisplay from "components/IconDisplay";
import { TicketServices } from "services";

const TICKET_CONFIG = {
  screenName: "Ticket",
  header: (
    <p className="admin__ticketsList-header">
      <IconDisplay type="ticket" className="" /> All Tickets
    </p>
  ),
  columnHeaders: [
    { label: "Ticket ID", key: "_id", type: "string" },
    { label: "Consumer Name", key: "consumerName", type: "string" },
    { label: "Created On", key: "createdAt", type: "date" },
    { label: "Category", key: "category", type: "string" },
    { label: "Status", key: "activeStatus", type: "string" },
    { label: "Actions", key: "actions", type: "action" },
  ],
  sortTableViaColumn: (headerVal) => {
    console.log(`Sorting via ${headerVal}`);
  },
  fetchListData: TicketServices.getTicketsList,
  dataKey: "tickets",
  dataUnavailableMessage: `No data to display`,
};

export default TICKET_CONFIG;
