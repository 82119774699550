import IconDisplay from "components/IconDisplay";
import MyTicketsList from "pages/Tickets/MyTickets/MyTicketsList";
import { TICKET_TABS_LIST } from "constants/TicketInputs";

const TICKET_TABS_CONFIG = {
  screenName: (
    <p className="myTickets__header-text">
      <IconDisplay type="ticket" id="iconTicket" /> My Tickets
    </p>
  ),
  screenKey: "tickets",
  tabsHeaderList: () =>
    TICKET_TABS_LIST.map(({ label, ticketStatus }) => ({
      label,
      content: (
        <MyTicketsList ticketStatus={ticketStatus} />
      ),
    })),
  renderTabsList: [],
};

export default TICKET_TABS_CONFIG;
